import React from 'react'
import { useLojinhaContext } from '@lojinha/context'
import { LocalizationModalType } from '@lojinha/context/src/types'
import { Box, ChatButton } from '@lojinha/design-system'
import { Footer } from '@lojinha/footer'
import { HandTalk } from '@lojinha/handtalk'
import { Header } from '@lojinha/header'
import { Bag } from '@lojinha/bag'
import { useRouter } from 'next/router'
import { hasSearchParam } from '@lojinha/helpers'
import { Search } from '../search'
import { t } from '../../dictionary'
import { LocalizationModal } from '../../modules/localization-modal'
import { BottomBox } from '../bottom-box'
import { BackToTopButton } from '../../components/back-to-top-button'
import { AgeRestrictionModal } from '../age-restriction-modal'
import { isHomepage } from '../../helpers/isHomepage'
import { useChat } from './hook'

export const MainLayout = ({
  children,
  hasMarginBottom = true,
  enabledBackToTop,
}: {
  children: React.ReactNode
  hasMarginBottom?: boolean
  enabledBackToTop?: boolean
}) => {
  const router = useRouter()
  const { asPath: pageUrl } = router
  const isSearchOpen = hasSearchParam(router)

  const {
    isChatLoading,
    localizationModal,
    setBagOpen,
    setLocalizationModal,
    address,
    ageRestrictionModal,
  } = useLojinhaContext()

  const { onOpenChat } = useChat()

  const onClickWillDelivery = () =>
    setLocalizationModal({ openedModal: LocalizationModalType.Address })

  const onClickBag = () => setBagOpen(true)

  const addressLabel = address && t.singleAddress(address)

  return (
    <>
      <BottomBox>
        {enabledBackToTop && <BackToTopButton />}
        <ChatButton
          onOpenChatRequest={onOpenChat}
          chatLoading={!!isChatLoading}
          text={t.help}
          ariaLabel={t.askHelp}
        />
      </BottomBox>
      <Header
        modalOpened={!!localizationModal || !!ageRestrictionModal}
        onClickBag={onClickBag}
        onClickWillDelivery={onClickWillDelivery}
        address={addressLabel}
        isHome={isHomepage(pageUrl)}
        showSearch={true}
      />
      <Box
        marginTop={
          isHomepage(pageUrl) ? ['x0', 'x0', 'x96'] : ['x56', 'x56', 'x96']
        }
        marginBottom={hasMarginBottom ? ['x32', 'x32', 'x48'] : 'x0'}
      >
        {isSearchOpen ? <Search /> : children}
      </Box>
      <Bag />
      <LocalizationModal />
      <AgeRestrictionModal />
      <Footer>
        <HandTalk withButton />
      </Footer>
    </>
  )
}
