import { LocationPointer } from '@lojinha/design-system/src/icons'
import React from 'react'
import { t } from '../../../dictionary'
import { HeaderDesktopButton } from '../../header-desktop-button'

export const DropdownAnchor = ({
  currentAddress,
}: {
  currentAddress: string
}) => (
  <HeaderDesktopButton
    icon={<LocationPointer color="black" />}
    secondaryText={t.deliveryAddress}
    mainText={currentAddress}
    textEllipsis
  />
)
