import { Box, Text } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import { MarketableKind } from '@lojinha/palantir'
import React, { useEffect, useState } from 'react'
import { t } from '../../../dictionary'
import { useOnScreen } from '../../../helpers/useOnScreen'
import { useRefCallback } from '../../../helpers/useRefCallback'
import { AddToCartButton } from '../../add-to-cart-button'
import { BadgesGroup } from '../components/badges-group'
import { CardWrapper } from '../components/card'
import { ItemImage } from '../components/item-image'
import { PriceAndWeight } from '../components/price-and-weight'
import { ProductCardType } from '../types'

export const ProductCardIndividual = ({
  id,
  kind,
  title,
  badges,
  imageUrl,
  isAvailable,
  isAgeRestricted,
  price,
  priceFrom,
  weight,
  sku,
  imageAriaLabel,
  eventList,
  positionInList,
  onItemClick,
  onRenderItem,
  slug,
}: ProductCardType) => {
  const [isCardHover, setIsCardHover] = useState(false)

  const [itemCard] = useRefCallback<HTMLDivElement>()
  const { isRendered } = useOnScreen(itemCard)

  useEffect(() => {
    isRendered && onRenderItem && onRenderItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered])

  const onMouseOver = () => {
    setIsCardHover(true)
  }

  const onMouseLeave = () => {
    setIsCardHover(false)
  }

  return (
    <CardWrapper
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ariaLabel={t.productCardAriaLabel({
        title,
        badges,
        price,
        priceFrom,
        weight,
      })}
      onClick={onItemClick}
      slug={slug}
    >
      <div ref={itemCard}>
        <ItemImage
          url={imageUrl}
          ariaLabel={imageAriaLabel}
          cardType="individual"
          isHover={isCardHover}
        />
        <Box px="x16" marginTop="x16" htmlAttrs={{ style: { height: '16px' } }}>
          {badges && <BadgesGroup badges={badges} />}
        </Box>
        <Box px="x16" marginTop="x16">
          <Text
            color={'black'}
            textSize={['x14', 'x2']}
            {...testId('product-card-title')}
          >
            {title}
          </Text>
        </Box>
      </div>

      <div>
        <Box px="x16">
          <PriceAndWeight price={price} priceFrom={priceFrom} weight={weight} />
        </Box>
        <Box padding="x8">
          <AddToCartButton
            isHover={isCardHover}
            isAvailable={isAvailable}
            isAgeRestricted={isAgeRestricted}
            eventList={eventList}
            positionInList={positionInList}
            item={{
              id,
              imageUrl,
              kind: kind as MarketableKind,
              name: title,
              price,
              priceFrom,
              quantity: 0,
              sku,
            }}
          />
        </Box>
      </div>
    </CardWrapper>
  )
}
