import {
  MarketableKind,
  PaymentMethodBrand,
  PaymentMethodType,
} from '@lojinha/palantir'

export enum AddressKind {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
}

export type Address = {
  id: string
  kind: AddressKind
  street: string
  number?: string | null
  complement?: string | null
  zipCode: string
  neighborhood: string
  city: string
  lastUsedAt?: string
  state: string
  country: string
  coordinates: {
    latitude: number
    longitude: number
  }
  nearestDistributionCenter?: {
    id: string
  }
  deliveryCost?: number
}

export type DeliveryDatePeriod = {
  id: string
  name: string
  timeRange: number[]
}

export type DeliveryDate = {
  date: string
  period: DeliveryDatePeriod
}

export type SavedCard = {
  id?: string | null
  type: PaymentMethodType
  lastUsedAt: Date
  brand?: PaymentMethodBrand | null
  cardId?: string | null
  holderName?: string | null
  holderDocument?: string | null
  expirationDate?: string | null
  lastDigits?: string | null
}

export type Coupon = {
  id: string
  name: string
  code: string
  type: string
  value: number
  isProductCoupon: boolean
  isFreeDeliveryCoupon: boolean
}

export type RoutePath =
  | '/delivery'
  | '/delivery/schedule'
  | '/delivery/new-address'
  | '/payment'
  | '/payment/new-card'
  | '/review'
  | '/confirmation'
  | '/identification'
  | '/payment/select-brand'

export type DeliveryKind = 'DELIVERY' | 'PICKUP'

export enum MacroPaymentMethod {
  bankSlip = 'BOLETO',
  creditCard = 'CREDIT_CARD',
  restaurantVoucher = 'RESTAURANT_VOUCHER',
  marketVoucher = 'MARKET_VOUCHER',
  paymentOffline = 'PAYMENT_OFFLINE',
  ticketOnline = 'TICKET_ONLINE',
}

type PaymentBrandName =
  | 'ALELO'
  | 'TICKET'
  | 'VEROCARD'
  | 'SODEXO_ALIMENTACAO'
  | 'SODEXO_REFEICAO'
  | 'VR_ALIMENTACAO'
  | 'VR_REFEICAO'
  | 'GREENCARD'
  | 'BEN'
  | 'UP_BRASIL'

export type VoucherMethod = {
  brandName: PaymentBrandName
  isOffline: boolean
  paymentType: MacroPaymentMethod
  humanName: string
}

export type CartItem = {
  id: string
  imageUrl: string
  kind: MarketableKind
  name: string
  sku: string
  price: number
  priceFrom?: number | null
  quantity: number
  isAgeRestricted?: boolean
  subtitle?: string
}

export type CartItemGroup = {
  id?: string
  item: Omit<CartItem, 'quantity'>
  quantity: number
  kind?: MarketableKind
}

export type BagProps = {
  cartItems: CartItemGroup[]
  quantity: number
  subtotal?: number
  subtotalPriceFrom?: number
  createdAt?: Date
}

export type CheckoutVisitor = 'newVisitor' | 'returningVisitor'

export enum LocalizationModalType {
  Address,
  InsideDeliveryArea,
  OutsideDeliveryAreaForm,
  OutsideDeiveryAreaSubmitted,
  UnavailableItems,
}

export type UnavailableItemInfo = {
  id: string
  name: string
}

export type LocalizationModal = {
  openedModal: LocalizationModalType
  items?: UnavailableItemInfo[]
}

export enum BagFeedbacks {
  allItemsAdded = 'allItemsAdded',
  allItemsRemoved = 'allItemsRemoved',
  someItemsRemoved = 'someItemsRemoved',
  ageRestrictedItemsRemoved = 'ageRestrictedItemsRemoved',
}

export type User = { email: string; name: string }
