import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Box, tabNavigationStyle, Text } from '@lojinha/design-system'
import { ColorProps } from '@lojinha/design-system/src/theme'
import { HtmlAttrs } from '@lojinha/design-system/src/baseProps'

const ellipsisStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MainText = styled(Text)<{
  underlineOnHover: boolean
  ellipsis?: boolean
}>(
  ({ underlineOnHover, ellipsis }) => `
  ${ellipsis ? ellipsisStyle : ''}

  &:hover,
  &:active,
  &:focus {
    ${underlineOnHover ? 'text-decoration: underline;' : ''}
  }
`
)

const Shade = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  border-radius: inherit;
  padding: ${theme.space.x12} ${theme.space.x24};
  height: ${theme.space.x48};
`
)

const Container = styled.button<{
  shadowOnHover: boolean
  background: ColorProps
  fontColor: ColorProps
  hoverColor: ColorProps
  htmlAttrs?: HtmlAttrs
}>(
  ({ theme, shadowOnHover, fontColor, hoverColor, background }) => `
  border-width: 0;
  border-radius: ${theme.space.x48};
  cursor: pointer;
  height: ${theme.space.x48};
  width: auto;
  transition: box-shadow 0.2s ease-in-out;

  color: ${theme.colors[fontColor]};
  background: ${theme.colors[background]};

  svg {
    path {
      fill: ${theme.colors[fontColor]};
    }
  }

  &:hover,
  &:active,
  &:focus-visible {
    background: ${theme.colors[hoverColor]};
    transition: background-color 0.2s cubic-bezier(0.22, 1, 0.36, 1);
    box-shadow: ${shadowOnHover ? theme.shadows.gray24 : 'none'};

    ${Shade} {
      background: ${shadowOnHover ? 'rgba(0, 0, 0, 0.24)' : 'none'};
    }
  }
`,
  tabNavigationStyle
)

enum Variants {
  primary = 'primary',
  secondary = 'secondary',
}

const styles: Record<
  Variants,
  { background: ColorProps; fontColor: ColorProps; hoverColor: ColorProps }
> = {
  [Variants.primary]: {
    background: 'white',
    fontColor: 'black',
    hoverColor: 'gray100',
  },
  [Variants.secondary]: {
    background: 'success',
    fontColor: 'white',
    hoverColor: 'success',
  },
}

type Props = {
  icon: React.ReactElement<SVGElement> | HTMLImageElement
  mainText: string
  secondaryText?: string
  ariaLabel?: string
  /** If true, main text has an ellipsis */
  textEllipsis?: boolean
  /** Default true, secondary text is underlined on hover */
  underlineOnHover?: boolean
  /** Default false, button has shadow on hover */
  shadowOnHover?: boolean
  /** Default primary */
  variant?: keyof typeof Variants
  onClick?: () => void
  onHover?: () => void
  onMouseOut?: (event: any) => void
  onBlur?: (event: any) => void
}

export const HeaderDesktopButton: FC<Props> = ({
  icon,
  mainText,
  secondaryText,
  ariaLabel,
  textEllipsis,
  underlineOnHover = true,
  shadowOnHover = false,
  variant = 'primary',
  onClick,
  onHover,
  onMouseOut,
  onBlur,
}) => {
  return (
    <Container
      aria-label={ariaLabel}
      onClick={onClick}
      onFocus={onHover}
      onMouseOver={onHover}
      onMouseOut={onMouseOut}
      onBlur={onBlur}
      shadowOnHover={shadowOnHover}
      background={styles[variant].background}
      fontColor={styles[variant].fontColor}
      hoverColor={styles[variant].hoverColor}
    >
      <Shade>
        <Box marginRight="x8" display="flex">
          {icon}
        </Box>
        <Box maxWidth="150px">
          <Text textTransform="none" textSize="x12">
            {secondaryText}
          </Text>
          <MainText
            isBold
            textSize="x14"
            underlineOnHover={underlineOnHover}
            ellipsis={textEllipsis}
          >
            {mainText}
          </MainText>
        </Box>
      </Shade>
    </Container>
  )
}
