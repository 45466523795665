import { Box, Text } from '@lojinha/design-system'
import { MarketableKind } from '@lojinha/palantir'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useOnScreen } from '../../../helpers/useOnScreen'
import { useRefCallback } from '../../../helpers/useRefCallback'
import { t } from '../../../dictionary'
import { AddToCartButton } from '../../add-to-cart-button'
import { BadgesGroup } from '../components/badges-group'
import { CardWrapper } from '../components/card'
import { ItemImage } from '../components/item-image'
import { PricePerDay } from '../components/price-per-day'
import { ProductKitCardType } from '../types'
import { Price } from '../components/price'

const Content = styled(Box)<{ isLarge: boolean }>(
  ({ theme, isLarge }) => `
  height: 100%;
  grid-template-columns: ${isLarge ? '88px auto max-content' : '88px auto'};
  ${isLarge ? 'grid-template-rows: 1fr;' : ''}

  grid-template-areas: 'image content' 'image content';
  grid-gap: ${theme.space.x16};
  grid-row-gap: 0;
  padding: ${theme.space.x24} ${theme.space.x16} ${theme.space.x16};
  height: ${isLarge ? '136px' : '192px'};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray200};
  border-radius: ${theme.space.x8};

  ${theme.media.below('sm')}{
    padding: 
      ${theme.space.x16} 
      ${theme.space.x8} 
      ${theme.space.x8} 
      ${theme.space.x16};
    flex-direction: column;
    justify-content: space-between;
    height: 176px;
  }
`
)

export const ProductCardKit = ({
  id,
  kind,
  title,
  badges,
  imageUrl,
  isAvailable,
  isAgeRestricted,
  price,
  priceFrom,
  pricePerDay,
  quantity,
  sku,
  imageAriaLabel,
  description,
  isLarge = false,
  eventList,
  positionInList,
  onItemClick,
  onRenderItem,
  slug,
}: ProductKitCardType) => {
  const [isCardHover, setIsCardHover] = useState(false)

  const [itemCard] = useRefCallback<HTMLDivElement>()
  const { isRendered } = useOnScreen(itemCard)

  useEffect(() => {
    isRendered && onRenderItem && onRenderItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered])

  const onMouseOver = () => {
    setIsCardHover(true)
  }

  const onMouseLeave = () => {
    setIsCardHover(false)
  }

  return (
    <Box height={['176px', isLarge ? '136px' : '192px']}>
      <CardWrapper
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        ariaLabel={t.productKitCardAriaLabel({
          title,
          badges,
          price,
          priceFrom,
          pricePerDay,
        })}
        onClick={onItemClick}
        slug={slug}
      >
        <Content
          display={['flex', 'grid']}
          isLarge={isLarge}
          htmlAttrs={{
            ref: itemCard,
          }}
        >
          <Box
            display={['none', 'flex']}
            alignItems="center"
            justifyContent="center"
            htmlAttrs={{ style: { gridArea: 'image' } }}
          >
            <ItemImage
              url={imageUrl}
              ariaLabel={imageAriaLabel}
              cardType="kit"
              isHover={isCardHover}
            />
          </Box>
          <div>
            <Box htmlAttrs={{ style: { height: '16px' } }}>
              {badges && <BadgesGroup badges={badges} />}
            </Box>
            <Box marginTop="x16">
              <Text color={'black'} textSize={['x14', 'x2']}>
                {title}
              </Text>
              <Text
                textSize={['x1', 'x14']}
                color="gray700"
                htmlAttrs={{
                  style: {
                    height: '32px',
                    maxWidth: '446px',
                    overflow: 'hidden',
                  },
                  title: description,
                }}
              >
                {description}
              </Text>
            </Box>
          </div>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              py={['x0', isLarge ? 'x8' : 'x0']}
            >
              <Box py="x8" px={['x0', isLarge ? 'x16' : 'x0']}>
                {price === pricePerDay ? (
                  <Price price={price} priceFrom={priceFrom} />
                ) : (
                  <PricePerDay
                    price={price}
                    priceFrom={priceFrom}
                    pricePerDay={pricePerDay}
                  />
                )}
              </Box>
              <Box
                minWidth={['123px', '216px']}
                marginTop={['x0', isLarge ? 'x8' : 'x0']}
              >
                <AddToCartButton
                  eventList={eventList}
                  positionInList={positionInList}
                  quantity={quantity ?? 0}
                  isHover={isCardHover}
                  isAvailable={isAvailable}
                  isAgeRestricted={isAgeRestricted}
                  item={{
                    id,
                    imageUrl,
                    kind: kind as MarketableKind,
                    name: title,
                    price,
                    quantity: quantity ?? 0,
                    priceFrom,
                    sku,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Content>
      </CardWrapper>
    </Box>
  )
}
