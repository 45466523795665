import { Address, useZipCode } from '@lojinha/location'
import React, { FC } from 'react'
import { AddressFormUI } from './ui'

type AddressFormProps = {
  onSubmit: (isInDeliveryArea: boolean, address?: Address) => void
}

export const AddressForm: FC<AddressFormProps> = ({ onSubmit }) => {
  const { checkCoordinates, fetchAddressByZipCode } = useZipCode()

  const onClick = async (fetchedAddress: Address) => {
    const { valid, nearestDistributionCenter } = await checkCoordinates({
      zipCode: fetchedAddress.zipCode,
      number: fetchedAddress.number || '',
    })
    onSubmit(valid, {
      ...fetchedAddress,
      nearestDistributionCenter: nearestDistributionCenter
        ? { id: nearestDistributionCenter }
        : undefined,
    })
  }

  return (
    <AddressFormUI
      onSubmit={onClick}
      fetchAddressByZipCode={fetchAddressByZipCode}
    />
  )
}
