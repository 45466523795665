import React from 'react'

import { Stack } from '@lojinha/design-system'
import { MenuNavigationProps } from '../../../types'
import { t } from '../../../dictionary'
import { MenuNavigation } from './components/menu-navigation'
import { MenuAnchor } from './components/menu-anchor'

export const MenuDesktopUI = ({
  isMenuOpen,
  handleClickMenu,
  onCloseMenu,
  onOpenMenu,
  menuItems,
  closeMenuOnBlur,
  selectedSubMenu,
  setSelectedSubMenu,
  handlerNavigationRef,
  categoryItemRef,
  submenuItemRef,
  onPanelKeyboardNavigation,
  onCategoryKeyboardNavigation,
  onSubmenuKeyboardNavigation,
}: MenuNavigationProps) => (
  <div className="menu" onMouseLeave={onCloseMenu} onBlur={closeMenuOnBlur}>
    <Stack
      layout="horizontal"
      margin="x0"
      as="ul"
      htmlAttrs={{
        style: {
          position: 'relative',
          zIndex: 1,
        },
      }}
    >
      <li key={t.home} role="menuitem" style={{ listStyle: 'none' }}>
        <MenuAnchor
          label={t.home}
          route={'/'}
          onClick={onCloseMenu}
          htmlAttrs={{
            onMouseOver: onCloseMenu,
            onFocus: onCloseMenu,
          }}
        />
      </li>
      <MenuAnchor
        label={t.categories}
        isSelected={isMenuOpen}
        htmlAttrs={{
          onFocus: onOpenMenu,
          onMouseOver: onOpenMenu,
          onMouseOut: closeMenuOnBlur,
          onBlur: closeMenuOnBlur,
          onKeyDown: onPanelKeyboardNavigation,
          role: 'button',
          'aria-expanded': isMenuOpen,
          as: 'li',
          tabIndex: 0,
          ref: handlerNavigationRef,
        }}
      />
    </Stack>
    <MenuNavigation
      isMenuOpen={isMenuOpen}
      onCloseMenu={onCloseMenu}
      menuItems={menuItems}
      handleClickMenu={handleClickMenu}
      selectedSubMenu={selectedSubMenu}
      setSelectedSubMenu={setSelectedSubMenu}
      categoryItemRef={categoryItemRef}
      submenuItemRef={submenuItemRef}
      onPanelKeyboardNavigation={onPanelKeyboardNavigation}
      onCategoryKeyboardNavigation={onCategoryKeyboardNavigation}
      onSubmenuKeyboardNavigation={onSubmenuKeyboardNavigation}
    />
  </div>
)
