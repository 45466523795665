import { useState, useEffect } from 'react'
import { theme } from '@lojinha/design-system'

export const useHideOn = () => {
  const [hideOn, setHideOn] = useState<'desktop' | 'mobile'>()

  const handleResize = () => {
    setHideOn(window.innerWidth < theme.windowSizesPx.md ? 'desktop' : 'mobile')
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    hideOn,
  }
}
