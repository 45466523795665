import React, { FC, useRef, useState } from 'react'
import {
  Anchor,
  ButtonIcon,
  Stack,
  tabNavigationStyle,
} from '@lojinha/design-system'
import { User, UserCircle } from '@lojinha/design-system/src/icons'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { useLojinhaContext } from '@lojinha/context'
import {
  numberToCurrency,
  numberToCurrencyAriaLabel,
  testId,
  clearCookies,
} from '@lojinha/helpers'
import { discardAuthToken } from '@lojinha/palantir'
import { t } from '../../dictionary'
import { HeaderDesktopButton } from '../header-desktop-button'
import { ACCOUNT_MENU_DATA } from './accountMenu'

const disabledOpacity = css`
  opacity: 0;
  visibility: hidden;
  transition: background-color 200ms ease, opacity 300ms ease;
`

const Wrapper = styled.div<{ isActive: boolean }>(
  ({ theme, isActive }) => `
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-35%);
    width: 288px;
    box-shadow: ${theme.shadows.gray16};
    border-radius: ${theme.space.x8};
    transition: background-color 200ms ease, opacity 300ms ease;
    opacity: 1;
    background: white;

    ${!isActive ? disabledOpacity : ''}

    ${theme.media.below('lg')} {
      left: -185%;

    }

    ${theme.media.below('sm')} {
      left: -185%;
      top: 115%;
    }
  `
)

const LinkContainer = styled.li(
  ({ theme }) => `
  list-style-type: none;
  padding: ${theme.space.x12} ${theme.space.x16};

  &:hover {
    &:last-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: ${theme.space.x8};
      border-bottom-left-radius: ${theme.space.x8};
    }
    border-radius: 0;
    background: ${theme.colors.gray100};
  }
  `,
  tabNavigationStyle
)

const CustomAnchor = styled(Anchor)<{ isMGM: boolean }>(
  ({ theme, isMGM }) => `
    color: ${isMGM ? theme.colors.success : theme.colors.black};
  `
)

export const AccountButton: FC<{ isMobile?: boolean }> = ({
  isMobile = false,
}) => {
  const {
    isAuthenticated,
    referralRewardAmount,
    clearContext,
    setIsContextLoaded,
    setRouteAfterLogin,
    user,
  } = useLojinhaContext()

  const router = useRouter()

  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { menu } = ACCOUNT_MENU_DATA

  const onLogout = () => {
    setIsActiveMenu(false)
    discardAuthToken()
    localStorage.clear()
    sessionStorage.removeItem('hasAgeRestrictionModal')
    clearCookies()
    clearContext()
    return setTimeout(() => {
      setIsContextLoaded(true)
      router.reload()
    }, 1000)
  }

  const onButtonClick = () => {
    if (!isAuthenticated) {
      setRouteAfterLogin(router.asPath)
      router.push('/identification')
    }
  }

  const openMenu = () => {
    if (isAuthenticated) setIsActiveMenu(true)
  }

  const closeMenu = () => {
    setIsActiveMenu(false)
  }

  const closeMenuOnBlur = (event: any) => {
    const { relatedTarget } = event
    const blurOnMenuItem = relatedTarget
      ? relatedTarget.matches('li') || relatedTarget.matches('a')
      : false
    if (!blurOnMenuItem) closeMenu()
  }

  const redirectToButton = () => {
    if (buttonRef?.current !== null && isAuthenticated) {
      buttonRef.current?.focus()
    }
  }

  const redirectToMenu = () => {
    if (anchorRef?.current !== null && isAuthenticated) {
      anchorRef.current?.focus()
    }
  }

  const onMenuFocus = (event: any) => {
    const { key } = event

    if (key === 'Shift' || key === 'Escape') closeMenu()

    if (
      (key === 'ArrowDown' || key === 'ArrowRight') &&
      redirectToMenu &&
      isAuthenticated
    ) {
      setIsActiveMenu(true)
      redirectToMenu()
    }
  }

  const onButtonFocus = (event: any) => {
    const { key } = event

    if (
      (key === 'Escape' || key === 'ArrowUp' || key === 'ArrowLeft') &&
      redirectToButton &&
      isAuthenticated
    ) {
      redirectToButton()
      closeMenu()
    }
  }

  const isMGM = (title: string) => title.includes(t.menu.mgm)
  const customTitle = (title: string) =>
    isMGM(title) ? title.concat(numberToCurrency(referralRewardAmount)) : title
  const customAriaLabel = (title: string) =>
    isMGM(title)
      ? title.concat(numberToCurrencyAriaLabel(referralRewardAmount))
      : title

  const secondaryText = isAuthenticated ? t.menu.account.helloUser : ''
  const mainText = isAuthenticated
    ? user?.name || t.menu.account.profile
    : t.menu.account.login

  return (
    <div style={{ position: 'relative' }}>
      {isMobile ? (
        <ButtonIcon
          icon={<User size="x3" />}
          ariaLabel={t.openProfileAlt}
          onClick={onButtonClick}
          htmlAttrs={{
            ref: buttonRef,
            onFocus: openMenu,
            onMouseOver: openMenu,
            onMouseOut: closeMenuOnBlur,
            onKeyDown: onMenuFocus,
          }}
          {...testId('account-button-icon')}
        />
      ) : (
        <HeaderDesktopButton
          icon={<UserCircle size="x3" />}
          ariaLabel={t.openProfileAlt}
          onClick={onButtonClick}
          onHover={openMenu}
          onMouseOut={closeMenuOnBlur}
          onBlur={closeMenuOnBlur}
          textEllipsis
          mainText={mainText}
          secondaryText={user?.name && secondaryText}
          {...testId('account-button-icon')}
        />
      )}
      <Wrapper
        isActive={isActiveMenu}
        aria-hidden={!isActiveMenu}
        onMouseLeave={closeMenu}
        onKeyDown={onButtonFocus}
        onBlur={closeMenuOnBlur}
        {...testId('wrapper-account-menu')}
      >
        <Stack
          as="ul"
          margin="x0"
          aria-hidden={!isActiveMenu}
          htmlAttrs={{ style: { borderRadius: 'inherit' } }}
        >
          {menu.map(({ id, title, route, Icon, onClick, index }) => {
            const handleOnClick = () => {
              if (id === 'logout') {
                return onLogout()
              } else if (onClick) {
                return onClick()
              }
            }

            return (
              <LinkContainer key={`menu-item-${index}`} onClick={handleOnClick}>
                <Stack layout="horizontal" alignItems="center">
                  <Icon size="x3" />
                  <NextLink href={route || ''} passHref>
                    <CustomAnchor
                      color="success"
                      isBold
                      isSecondary
                      isUppercased
                      isBlock
                      textSize={'x12'}
                      htmlAttrs={{
                        tabIndex: isActiveMenu ? 0 : -1,
                        'aria-label': customAriaLabel(title),
                      }}
                      {...testId(`anchor-account-menu-${index}`)}
                      isMGM={title.includes(t.menu.mgm)}
                    >
                      {customTitle(title)}
                    </CustomAnchor>
                  </NextLink>
                </Stack>
              </LinkContainer>
            )
          })}
        </Stack>
      </Wrapper>
    </div>
  )
}
