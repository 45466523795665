import * as imgix from '@lojinha/helpers'
import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'
import { sanitizeImage } from '@lojinha/helpers'
import { theme } from '@lojinha/design-system'

const sizeValue = css`
  height: ${theme.space.x96};
  width: ${theme.space.x96};
  min-width: ${theme.space.x96};
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${sizeValue}

  border-radius: ${theme.space.x8};
  background: ${theme.colors.gray100};

  & div {
    position: relative;
    border-radius: ${theme.space.x8};
    ${sizeValue};
  }

  & img {
    object-fit: cover;
    transition: transform 300ms;

    &:hover {
      transition: transform 300ms;
      transform: scale(1.1);
    }
  }
`

const PureImage = styled.img`
  position: absolute;
  object-fit: cover;
  ${sizeValue};
`

type Props = {
  url: string
  ariaLabel: string
}

export const ItemImage = ({ url, ariaLabel }: Props) => {
  const imageUrl = `${imgix.DOMAIN}${sanitizeImage({
    imageUrl: url,
    size: 'small',
  })}`

  const urlLqip = imgix.withParams(imageUrl, {
    px: 16,
    blur: 200,
    fm: 'webp',
  })

  const urlOptimized = imgix.withParams(imageUrl, {
    fm: 'webp',
  })

  return (
    <ImageWrapper aria-hidden={true}>
      <PureImage
        src={urlLqip}
        alt={ariaLabel}
        loading="lazy"
        aria-hidden={true}
      />
      <Image
        src={urlOptimized.replace(imgix.DOMAIN, '')}
        alt={ariaLabel}
        layout="fill"
      />
    </ImageWrapper>
  )
}
