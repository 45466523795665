import { Button, Grid, Input, Stack, Text } from '@lojinha/design-system'
import styled from 'styled-components'
import { testId } from '@lojinha/helpers'
import {
  addressComplementValidator,
  addressNumberValidator,
  formatZipCode,
} from '@lojinha/input-helpers'
import {
  Address,
  AddressData,
  MASKED_ZIP_CODE_LENGTH,
  ZipCodeResult,
} from '@lojinha/location'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LocationButton } from '../../../components/location-button'
import { useGeolocationZipCode } from '../../../components/location-button/hooks'
import { t } from '../../../dictionary'

type AddressFormUIProps = {
  onSubmit: (addrress: Address) => void
  fetchAddressByZipCode: (data: {
    zipCode: string
    number?: string
  }) => Promise<ZipCodeResult | undefined>
}

const ZipCodeContainer = styled(Stack)(
  ({ theme }) => `
    margin-bottom: ${theme.space.x40};

    input[name="zipCode"] {
      height: ${theme.space.x48};
      padding-top: ${theme.space.x16};
    } 
  `
)

export const AddressFormUI: FC<AddressFormUIProps> = ({
  onSubmit,
  fetchAddressByZipCode,
}) => {
  const {
    errors,
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState,
    trigger,
  } = useForm<AddressData>({
    mode: 'onTouched',
    shouldFocusError: true,
  })

  const watchedZipCode = watch('zipCode')
  const watchedCity = watch('city')
  const {
    zipCode: geolocationZipCode,
    getGeolocation,
  } = useGeolocationZipCode()

  const [isBrasilia, setIsBrasilia] = useState(watchedCity === 'Brasília')

  useEffect(() => {
    setIsBrasilia(watchedCity === 'Brasília')
  }, [watchedCity])

  const handleZipCodeUpdate = async (zipCode: string) => {
    if (zipCode.length === MASKED_ZIP_CODE_LENGTH) {
      const fetchedAddress = await fetchAddressByZipCode({
        zipCode: zipCode,
      })

      if (fetchedAddress && Object.keys(fetchedAddress).length) {
        setValue('city', fetchedAddress.city, {
          shouldValidate: true,
        })
        setValue('country', fetchedAddress.country!, {
          shouldValidate: true,
        })
        setValue('neighborhood', fetchedAddress.neighborhood!, {
          shouldValidate: true,
        })
        setValue('state', fetchedAddress.state, {
          shouldValidate: true,
        })
        setValue('street', fetchedAddress.street!, {
          shouldValidate: true,
        })

        return true
      } else {
        setError('zipCode', {
          type: t.addressValidation.invalidZipCode,
          message: t.addressValidation.invalidZipCode,
        })

        return false
      }
    }

    return false
  }

  useEffect(() => {
    if (!geolocationZipCode) return
    setValue('zipCode', geolocationZipCode, {
      shouldValidate: true,
    })
    handleZipCodeUpdate(geolocationZipCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocationZipCode])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      {...testId('address-localization-modal')}
    >
      <input type="hidden" name="state" ref={register({})} />
      <input type="hidden" name="city" ref={register({})} />
      <input type="hidden" name="country" ref={register({})} />
      <input type="hidden" name="street" ref={register({})} />
      <input type="hidden" name="neighborhood" ref={register({})} />
      <ZipCodeContainer margin="x32">
        <Text>
          {t.whereAreYou},{' '}
          <Text as="span" isBold>
            {t.tellUs}
          </Text>
        </Text>

        <Input
          error={errors.zipCode && t.addressValidation.invalidZipCode}
          label={t.zipCodeToDeliver}
          name="zipCode"
          autoComplete="postal-code"
          type="tel"
          ref={register({
            required: true,
            validate: async value => handleZipCodeUpdate(value),
          })}
          addon={<LocationButton onClick={getGeolocation} />}
          onChange={async event => {
            event.target.value = formatZipCode(event.target.value)
            trigger('zipCode')
          }}
        />
      </ZipCodeContainer>
      <Stack margin="x40">
        <Grid
          gridTemplateColumns={isBrasilia ? '1fr' : '1fr 1fr'}
          htmlAttrs={{ style: { maxWidth: '30rem' } }}
        >
          {!isBrasilia && (
            <Input
              error={errors.number && t.addressValidation.invalidNumber}
              label={t.number}
              name="number"
              autoComplete="address-line2"
              ref={register({
                required: true,
                maxLength: 15,
                validate: {
                  specialChars: value => addressNumberValidator(value),
                },
              })}
              onChange={() => trigger('number')}
            />
          )}
          <Input
            error={errors.complement && t.addressValidation.invalidComplement}
            label={t.complement}
            name="complement"
            autoComplete="address-line2"
            ref={register({
              required: isBrasilia,
              maxLength: 25,
              validate: {
                specialChars: value => addressComplementValidator(value),
              },
            })}
          />
        </Grid>
        <Button
          isBlock
          isLoading={formState.isSubmitting}
          isDisabled={
            !formState.isValid ||
            watchedZipCode?.length < MASKED_ZIP_CODE_LENGTH
          }
          {...testId('save-address-button')}
        >
          {t.continue}
        </Button>
      </Stack>
    </form>
  )
}
