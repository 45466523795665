import { BagFeedbacks, CartItemGroup } from '@lojinha/context/src/types'
import { Box, Drawer, theme } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import React, { FC } from 'react'
import styled from 'styled-components'
import { EmptyState } from '@lojinha/vitrine/src/components/empty-state'
import { t } from '../dictionary'
import { BagContent } from '../bag-content'
import { BagFooter } from '../bag-footer'
import { BagHeader } from '../bag-header'
import { BagFeedback } from '../bag-feedback'
import { useGoToCheckout, useShippingValue } from './hooks'

type BagUIProps = {
  isOpen: boolean
  totalItems: number
  items: CartItemGroup[]
  subtotal: number
  subtotalPriceFrom: number
  onRequestClose: () => void
  removedItems?: string[]
  feedback?: BagFeedbacks
}

const Container = styled(Box)<{ windowHeight: number }>(
  ({ windowHeight }) => `
  height: calc(${windowHeight}px - 200px);
  max-height: calc(${windowHeight}px - 200px);
  overflow-y: hidden;
`
)

const Footer = styled(Box)`
  box-shadow: ${theme.shadows.gray16};
  width: 100%;
  bottom: 0;
  flex-grow: 0;
`

export const BagUI: FC<BagUIProps> = ({
  isOpen,
  totalItems,
  items,
  subtotal,
  subtotalPriceFrom,
  onRequestClose,
  removedItems,
  feedback,
}) => {
  const { freeShippingValue } = useShippingValue()
  const { handleCartSyncAndRedirect, isLoading } = useGoToCheckout()

  const hasItems = items.length > 0

  const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0

  return (
    <Drawer
      noOverlay
      isOpen={isOpen}
      highlightedHeader
      hasCloseButton
      closeText={t.close}
      ariaLabelText={t.closeBag}
      onRequestClose={onRequestClose}
      heading={
        hasItems && (
          <Box
            as="p"
            display="flex"
            alignItems="center"
            minHeight={theme.space.x32}
            {...testId('bag-header')}
          >
            <BagHeader totalItems={totalItems} />
          </Box>
        )
      }
    >
      {hasItems ? (
        <>
          <Container windowHeight={windowHeight}>
            <Box
              {...testId('bag-content')}
              alignItems="center"
              paddingTop={'x16'}
              px={['x16', 'x40']}
              overflowY="auto"
              overflowX="hidden"
              flexGrow={1}
              height="100%"
            >
              <BagFeedback removedItems={removedItems} feedback={feedback} />
              <BagContent items={items} />
            </Box>
          </Container>
          <Footer {...testId('bag-footer')}>
            <BagFooter
              total={subtotal}
              totalPriceFrom={subtotalPriceFrom}
              buttonLabel={t.checkOut}
              isLoading={isLoading}
              onClickButton={handleCartSyncAndRedirect}
              freeShippingValue={freeShippingValue}
            />
          </Footer>
        </>
      ) : (
        <Box height="100%" py="x24" px={['x16', 'x40']}>
          <BagFeedback removedItems={removedItems} feedback={feedback} />
          <Box
            display="flex"
            alignItems="center"
            flexGrow={1}
            height="100%"
            px={['x16', 'x64']}
            {...testId('bag-empty-state')}
          >
            <EmptyState title={t.emptyBagTitle} text={t.emptyBagText} />
          </Box>
        </Box>
      )}
    </Drawer>
  )
}
