import React, { FC } from 'react'
import styled from 'styled-components'
import Img from 'next/image'
import { Box, tabNavigationStyle } from '@lojinha/design-system'
import { HandIcon } from './HandIcon'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > button {
    margin-bottom: ${({ theme }) => theme.space.x16};
  }

  ${({ theme }) => theme.media.above('md')} {
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    & > button {
      margin-left: ${({ theme }) => theme.space.x16};
      margin-bottom: 0px;
    }
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #003087;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 288px;
  cursor: pointer;
  span {
    line-height: 16px;
    font-family: Hind Madurai;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  ${tabNavigationStyle};
`

type Props = {
  onClick: () => void
}

export const HandTalkButton: FC<Props> = ({ onClick }) => {
  return (
    <>
      <Box display={['none', 'none', 'block']} marginBottom="x16">
        Acessibilidade
      </Box>

      <Container>
        <Button onClick={onClick}>
          <HandIcon />
          <span>Acessar em libras</span>
        </Button>
        <Img
          loader={({ src, width }) => `${src}?w=${width}&h=${90}&auto=format`}
          src={require('./selo-100x100.png')}
          alt="Selo amigo do surdo"
          loading="lazy"
          width={90}
          height={90}
        />
      </Container>
    </>
  )
}
