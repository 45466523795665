import React, { FC } from 'react'

export const HandIcon: FC = () => (
  <svg viewBox="0 0 100 100" width={32} height={32} fill="white">
    <path
      d="M97.2,47.3c-0.5,4.1-1.4,5.7-1.5,5.9c-0.3,0.5-0.9,0.6-1.3,0.3l0,0c-0.4-0.3-0.6-0.9-0.3-1.3l0,0
		c0,0,0.5-0.9,0.9-2.9l0.2-6c-0.3-1.5-0.8-2.6-0.8-2.6c-0.2-0.5,0-1,0.5-1.2s1,0,1.2,0.4C96.1,40,97.6,43.3,97.2,47.3 M91.5,52.1
		c-0.2,0.5-0.8,0.7-1.3,0.4c-0.5-0.2-0.7-0.8-0.4-1.3l0,0c0,0,0.4-0.9,0.6-3l-0.4-6c-0.5-1.5-1-2.5-1-2.5c-0.3-0.5-0.1-1,0.3-1.3
		c0.5-0.3,1-0.1,1.3,0.3c0.1,0.1,1.9,3.2,1.8,7.3C92.4,50.1,91.6,51.9,91.5,52.1 M87.4,49.7c-1.6,7-4.5,12.7-5.4,14.9
		c-1,2.2-1.5,5.3-1.3,12.7c0.2,7.4-0.2,12.6-0.4,14.5c-0.1,1-1.1,1.7-2.2,1.7c-1,0-2-0.6-2.2-2.1c-0.5-3.1-1.6-15-1.9-16.6
		c-0.2-0.9-1-2.4-1.8-2.4c-0.6,0-1.3,0.8-1.8,3c-1.2,5.4-3.1,17.9-3.7,20.7s-1.7,3.2-2.4,3.2c-0.3,0-0.5-0.1-0.5-0.1h-0.1
		c-0.4,0-2.4-0.1-2.3-2.5c0.1-2.7,2.4-20.4,2.3-22.1c0-1-0.3-1.9-0.8-1.9c-0.4,0-0.8,0.4-1.4,1.3c-1.5,2.3-9.6,16.6-12.1,21.4
		c-0.5,1-1.4,1.3-2.3,1.3c-0.7,0-1.3-0.2-1.6-0.4c-0.8-0.5-1.5-2.3-1.1-3.5c0.3-1.2,8.9-19.9,10.1-22.2c0.8-1.5,0.5-3.1-0.6-3.1
		c-0.5,0-1.3,0.4-2.1,1.4c-2.7,2.9-14.1,14.2-15.5,15.7c-0.6,0.7-1.2,1-1.8,1c-0.7,0-1.3-0.4-1.9-0.9c-1-1-1.3-2.4-0.3-3.6
		c0.5-0.6,4-5.2,7.9-10.2c1-1.3,2-2.7,3-4l0.6-0.8l0.2-0.2l0.5-0.7l0.1-0.4c0.1-0.2,0.3-0.4,0.4-0.6l0.2-0.3
		c0.1-0.2,0.3-0.4,0.4-0.6l0.2-0.3l0.4-0.5l0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5l0.3-0.4l0.3-0.4l0.2-0.3l0.3-0.4l0.2-0.3
		c0.1-0.1,0.2-0.3,0.2-0.4l0.2-0.3l0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3l0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5
		c2.1-4.3,5.9-10.9-1.1-10.9c-0.4,0-0.8,0-1.3,0.1c-2.8,0.3-4.8,0.4-6.2,0.4c-3.1,0-3.9-0.5-4.9-0.8c-1.4-0.5-3.4-2-2.7-3.3
		s4.2-0.7,5.6-1.2l1.4-0.5l0,0h0.1l0,0c0.7-0.2,3.6-1,6.5-2.2c1.3-0.5,2.6-1.1,3.7-1.7c4-2.3,13.1-6.2,15.5-6.6
		c1.1-0.2,2.3-0.3,3.4-0.3c1.6,0,3.2,0.2,4.3,0.7c1.9,0.8,3.7,4.9,11.5,8.4C85.6,39.3,89.1,42.6,87.4,49.7 M19.5,62.5
		c-2.6-6.8-3.3-13.1-3.7-15.4c-0.4-2.3-1.7-5.3-5.9-11.3c-4.3-6-6.8-10.6-7.7-12.3c-0.9-1.7,1.8-4.4,3.9-2
		c2.1,2.3,9.6,11.6,10.8,12.8c1.1,1.1,4.6,2.6,2.7-2.5c-2-5.2-7.4-16.6-8.4-19.3c-1.5-3.9,0.7-4.2,0.7-4.2s2-1.5,3.4,0.8
		c1.4,2.3,9.3,18.3,10.3,19.7c1,1.4,2.2,2,2.2-0.7C27.7,25.3,26.6,9,26,3.6c-0.2-1.9,1.8-2.9,2.8-2.9c1-0.1,2.5,1.1,2.9,2.2
		c0.4,1.2,3.6,21.5,4,24.1c0.3,2.6,2.6,3.8,3.2-0.1c0.6-3.9,3.9-19.6,4.2-21.6s1.7-2.4,3.1-2.1c1.4,0.3,2.4,1.3,2.3,2.9
		c-0.1,1.5-1.7,21.3-1.4,28c-2.7,1.2-6,2.2-7.3,2.5l-0.2,0.1l-0.2,0.1l-1.8,0.3c-0.2,0-0.7,0-1,0.1c-2.1,0.1-5.7,0.3-7.3,3.4
		c-0.8,1.5-0.9,3.3-0.3,4.9c1.2,3.1,4.5,4.6,5.5,5l0.2,0.1c1.4,0.6,2.9,1,6.3,1c1.7,0,3.8-0.1,6.4-0.4c-0.5,1.1-1.1,2.5-1.6,3.3
		l-0.4,0.7C44.5,57,41,61.9,34.9,69.8c-2.1-0.2-4.7-0.3-8.1,0.3C26.8,70.1,22,69.3,19.5,62.5 M24.1,75.1c-0.2,0.5-0.8,0.7-1.2,0.5
		l0,0c-0.1-0.1-3.5-1.4-5.9-4.7c-2.4-3.3-2.8-5.2-2.9-5.4c-0.1-0.5,0.2-1,0.7-1.1s1,0.2,1.1,0.7c0,0,0.2,1,1.3,2.8l4,4.6
		c1.3,0.9,2.3,1.4,2.3,1.4C24.1,74.1,24.3,74.6,24.1,75.1L24.1,75.1 M19.2,77.6c-0.2,0.5-0.8,0.6-1.3,0.4c-0.1-0.1-3.3-1.8-5.4-5.3
		s-2.3-5.4-2.3-5.6c-0.1-0.5,0.3-1,0.9-1c0.5-0.1,1,0.3,1,0.9l0,0c0,0,0.1,1,1,2.9l3.5,4.9c1.2,1,2.2,1.6,2.2,1.6
		C19.2,76.5,19.4,77.1,19.2,77.6L19.2,77.6"
    />
  </svg>
)
