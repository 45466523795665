import React, { useRef, useState } from 'react'
import { beholder } from '@lojinha/beholder'
import { Menu } from '../../../../types'

export const useMenuNavigation = (menu: Menu[]) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [selectedSubMenu, setSelectedSubMenu] = useState<Menu | null>(null)

  const handlerNavigationRef = useRef<HTMLDListElement>()
  const categoryItemRef = useRef<HTMLDListElement | HTMLAnchorElement>(null)
  const submenuItemRef = useRef<HTMLDListElement | HTMLAnchorElement>(null)

  const closeMenu = () => {
    setIsMenuOpen(false)
    setSelectedSubMenu(null)
  }

  const closeMenuOnBlur = (event: any) => {
    if (!event.relatedTarget) return
    const { relatedTarget } = event

    const blurOnMenuItem =
      relatedTarget.matches('a') ||
      relatedTarget.matches('nav') ||
      relatedTarget.matches('li')

    if (!blurOnMenuItem) closeMenu()
  }

  const openMenu = () => {
    setIsMenuOpen(true)
  }

  const closeMenuWithKeyboard = () => {
    handlerNavigationRef?.current?.focus()
    closeMenu()
  }

  const openMenuWithKeyboard = () => {
    openMenu()
    categoryItemRef.current?.focus()
  }

  const nextCategoryItemNavigation = () => {
    const currentIndex =
      menu.findIndex(element => element.title === selectedSubMenu?.title) + 1

    if (currentIndex !== menu.length) {
      setSelectedSubMenu(menu[currentIndex])
    }

    const nextElementToFocus = categoryItemRef.current
      ?.nextElementSibling as HTMLElement

    if (nextElementToFocus) nextElementToFocus?.focus()
  }

  const previousCategoryItemNavigation = () => {
    const currentIndex =
      menu.findIndex(element => element.title === selectedSubMenu?.title) - 1

    if (currentIndex === -1) return

    setSelectedSubMenu(menu[currentIndex])

    const previousElementToFocus = categoryItemRef.current
      ?.previousElementSibling as HTMLElement

    previousElementToFocus?.focus()
  }

  const onPanelKeyboardNavigation = (event: React.KeyboardEvent) => {
    const { key } = event

    if (key === 'Escape') {
      closeMenuWithKeyboard()
    }

    if (!selectedSubMenu && (key === 'Enter' || key === 'ArrowRight')) {
      openMenuWithKeyboard()
    }
  }

  const onCategoryKeyboardNavigation = (event: React.KeyboardEvent) => {
    const { key } = event

    if (key === 'ArrowDown') {
      nextCategoryItemNavigation()
    }

    if (key === 'ArrowUp') {
      previousCategoryItemNavigation()
    }

    if (key === 'ArrowRight') {
      submenuItemRef.current?.focus()
    }

    if (key === 'ArrowLeft') {
      closeMenuWithKeyboard()
    }
  }

  const onSubmenuKeyboardNavigation = (event: React.KeyboardEvent) => {
    const { key, currentTarget } = event
    if (key === 'ArrowDown') {
      const nextElementToFocused = currentTarget?.parentElement
        ?.nextElementSibling?.firstChild as HTMLElement

      nextElementToFocused?.focus()
    }

    if (key === 'ArrowUp') {
      const previousElementToFocused = currentTarget?.parentElement
        ?.previousElementSibling?.firstChild as HTMLElement

      previousElementToFocused?.focus()
    }

    if (key === 'ArrowLeft') {
      categoryItemRef?.current?.focus()
    }
  }

  const onClickMenuItem = (currentMenu: Menu) => {
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'access_category',
      label: currentMenu.title?.toLowerCase(),
      property: currentMenu.route,
    })
    closeMenu()
  }

  return {
    closeMenuOnBlur,
    closeMenu,
    isMenuOpen,
    openMenu,
    onClickMenuItem,
    setSelectedSubMenu,
    selectedSubMenu,
    handlerNavigationRef,
    categoryItemRef,
    submenuItemRef,
    onPanelKeyboardNavigation,
    onCategoryKeyboardNavigation,
    onSubmenuKeyboardNavigation,
  }
}
