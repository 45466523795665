import { ButtonIcon } from '@lojinha/design-system'
import { Search } from '@lojinha/design-system/src/icons'
import { testId, updateSearchParam } from '@lojinha/helpers'
import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { t } from '../../dictionary'

export const SearchButton: FC = () => {
  const router = useRouter()
  return (
    <ButtonIcon
      icon={<Search size="x3" />}
      ariaLabel={t.search}
      onClick={() => updateSearchParam(router, '')}
      {...testId('search-button-icon')}
      enabledIconBackgroundHover={false}
      enabledIconHover={false}
    />
  )
}
