import React from 'react'
import { Anchor, Grid, Stack } from '@lojinha/design-system'
import { DATA_FOOTER } from '../footer.i18n'
import { Group } from './group'

type column = {
  title?: string
  links: link[]
}

type link = {
  label: string
  ariaLabel?: string
  url: string
  externalLink?: boolean
}

const generateList = (data: column) => (
  <Stack as="ul" margin="x8">
    {data.links.map((item: link) => {
      return (
        <li key={item.url} style={{ listStyle: 'none' }}>
          <Anchor
            textSize="x1"
            href={item.url}
            isSecondary
            isBold
            isUppercased
            aria-label={
              item.ariaLabel ? `${item.ariaLabel}.` : `${item.label}.`
            }
          >
            {item.label}
          </Anchor>
        </li>
      )
    })}
  </Stack>
)

export const Links = () => (
  <Grid gridTemplateColumns="repeat(6, 1fr)">
    <Grid
      gridColumn={['1 / 7', '1 / 7', '1 / 3']}
      display={['block', 'block', 'grid']}
    >
      <Group title={DATA_FOOTER.comeMeet.title}>
        {generateList(DATA_FOOTER.comeMeet)}
      </Group>
    </Grid>
    <Grid
      gridColumn={['1 / 7', '1 / 7', '3 / 5']}
      display={['block', 'block', 'grid']}
    >
      <Group title={DATA_FOOTER.bePartner.title}>
        {generateList(DATA_FOOTER.bePartner)}
      </Group>
    </Grid>
    <Grid
      gridColumn={['1 / 7', '1 / 7', '5 / 7']}
      display={['block', 'block', 'grid']}
    >
      <Group title={DATA_FOOTER.contactUs.title}>
        <Stack margin="x32">
          {generateList(DATA_FOOTER.contactUs)}
          {generateList(DATA_FOOTER.helpTermsPolicies)}
        </Stack>
      </Group>
    </Grid>
  </Grid>
)
