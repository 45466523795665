import { Badge, BadgeColorPalette, Stack } from '@lojinha/design-system'
import { ItemTag } from '@lojinha/palantir'
import React from 'react'
import { itemTagsContentTranslate } from '../../../../dictionary/pt-formatters'
import { useWindowProperties } from '../../../../helpers/useWindowProperties'
import { filterBadges } from './logic'

type Props = {
  badges: ItemTag[]
}

export const BadgesGroup = ({ badges }: Props) => {
  const { isMobile } = useWindowProperties()

  const filteredBadges = filterBadges({
    badges,
    limitOfChars: isMobile ? 20 : 0,
  })

  return (
    <Stack layout="horizontal" margin="x4">
      {filteredBadges.map(badge => (
        <Badge
          key={badge.content}
          text={itemTagsContentTranslate[badge.content]}
          colorPalette={(badge.colorPalette as unknown) as BadgeColorPalette}
        />
      ))}
    </Stack>
  )
}
