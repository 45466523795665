import { gql } from '@lojinha/palantir'

export const DELIVERY_ADDRESS_SAVE_MUTATION = gql`
  mutation DeliveryAddressSave($input: SaveDeliveryAddressInput!) {
    saveDeliveryAddress(input: $input) {
      user {
        id
        addresses {
          totalCount
          nodes {
            ... on DeliveryAddress {
              lastUsedAt
              street
              number
              zipCode
              complement
              neighborhood
              city
              state
              country
              coordinates {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  }
`

export const ADDRESS_SEARCH_QUERY = gql`
  query AddressSearch($input: SearchAddressInput!) {
    searchAddress(input: $input) {
      city
      coordinates {
        latitude
        longitude
      }
      country
      neighborhood
      number
      state
      street
      zipCode
    }
  }
`

export const CHECK_COORDINATES = gql`
  query CheckCoordinates($input: ValidateAddressInput!) {
    validateCoordinates(input: $input) {
      valid
      ecommDistributionCenter {
        id
      }
    }
  }
`
