import styled from 'styled-components'
import { tabNavigationStyle } from '@lojinha/design-system'

export const Container = styled.button(
  ({ theme }) => `
    width: 100%;
    border: none;
    padding: ${theme.space.x14} ${theme.space.x24};
    background-color: ${theme.colors.gray100};
    border-bottom: 1px solid #ECECEC;
`
)

export const Input = styled.input(
  ({ theme }) => `
    width: 100%;
    font-size: ${theme.space.x14};
    font-weight: bold;
    color: ${theme.colors.black};
    margin-left: ${theme.space.x8};
    border: none;
    background-color: ${theme.colors.transparent};
    ::placeholder {
      color: ${theme.colors.gray700};
    }
    &:focus::placeholder {
      color: ${theme.colors.gray700};
    }
`
)

export const CloseButton = styled.button(
  ({ theme }) => `
    display: flex;
    background-color: ${theme.colors.transparent};
    border: none;
    svg path {
      fill: ${theme.colors.gray700};
    }
`,
  tabNavigationStyle
)
