import React, { FC } from 'react'
import { Bag, BagFilled } from '@lojinha/design-system/src/icons'
import { numberToCurrency, testId } from '@lojinha/helpers'
import { useLojinhaContext } from '@lojinha/context'
import { ButtonIcon } from '@lojinha/design-system'
import { t } from '../../dictionary'
import { HeaderDesktopButton } from '../header-desktop-button'

type BagButtonProps = {
  onClick?: () => void
  isMobile?: boolean
}

export const BagButton: FC<BagButtonProps> = ({
  onClick,
  isMobile = false,
}) => {
  const { bag } = useLojinhaContext()

  const quantity = bag?.quantity || ''
  const total = bag?.subtotal ?? 0

  const mainText = total ? numberToCurrency(total) : t.yourBag
  const secondaryText = quantity ? t.productsCount(quantity) : ''

  return (
    <>
      {isMobile ? (
        <ButtonIcon
          icon={<Bag color="black" size="x3" />}
          onClick={onClick}
          notification={quantity}
          ariaLabel={t.openBagAlt}
          {...testId('bag-button')}
        />
      ) : (
        <HeaderDesktopButton
          icon={<BagFilled size="x3" />}
          mainText={mainText}
          secondaryText={secondaryText}
          ariaLabel={t.openBagAlt}
          onClick={onClick}
          variant="secondary"
          shadowOnHover
          {...testId('bag-button')}
        />
      )}
    </>
  )
}
