import React, { FC } from 'react'
import { Box, Stack } from '@lojinha/design-system'
import { MenuItem } from '../menu-item'
import { Menu } from '../../../../../types'
import { t } from '../../../../../dictionary'
import { MobileMenuImage } from '../../../components/menu-image'

type SubMenuListProps = {
  items?: Menu[]
  selectedItem?: Menu | null
  onClick: (menu: Menu) => void
}

export const SubMenuList: FC<SubMenuListProps> = ({
  items,
  selectedItem,
  onClick,
}) => {
  return (
    <Box paddingTop="x40">
      <Stack margin="x32">
        {items?.map(item => (
          <MenuItem
            key={`${item.title}-${item.route}`}
            item={item}
            onClick={onClick}
          />
        ))}
        <MenuItem
          key={selectedItem?.route}
          item={{
            title: t.seeAll,
            route: selectedItem?.route,
            isNew: false,
          }}
          onClick={onClick}
        />
      </Stack>
      {selectedItem?.image && <MobileMenuImage image={selectedItem.image} />}
    </Box>
  )
}
