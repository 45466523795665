// Adapted from https://usehooks.com/useWindowSize/
import { theme } from '@lojinha/design-system'
import { useEffect, useState } from 'react'

export const useWindowProperties = () => {
  const [windowProperties, setWindowProperties] = useState<{
    width?: number
    height?: number
    isMobile?: boolean
    isDesktop?: boolean
    isTablet?: boolean
  }>({
    width: undefined,
    height: undefined,
    isMobile: undefined,
    isDesktop: undefined,
    isTablet: undefined,
  })

  const handleResize = () => {
    setWindowProperties({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < theme.windowSizesPx.sm,
      isDesktop: window.innerWidth > theme.windowSizesPx.md,
      isTablet:
        window.innerWidth >= theme.windowSizesPx.sm &&
        window.innerWidth <= theme.windowSizesPx.md,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowProperties
}
