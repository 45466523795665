import { Box, Grid, theme } from '@lojinha/design-system'
import React, { FC } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { hasSearchParam } from '@lojinha/helpers'
import {
  AccountButton,
  BagButton,
  Logo,
  Menu,
  WillDeliver,
  SearchButton,
  SearchInputMobile,
} from '../../components'

const StickySection = styled.header<{ modalOpened?: boolean }>(
  ({ modalOpened }) => `
  position: fixed;
  width: 100%;
  height: ${theme.space.x56};
  top: -${modalOpened ? theme.space.x56 : 0};
  background-color: ${theme.colors.white};
  z-index: 9;
  box-shadow: ${theme.shadows.gray16};
`
)

const Container = styled(Grid)`
  grid-gap: 0;
  li {
    align-items: center;
    justify-content: center;

    &:before {
      content: none;
    }
  }
`

type HeaderMobileProps = {
  onClickBag?: () => void
  modalOpened?: boolean
  address?: string
  onClickWillDelivery: () => void
  isHome: boolean
  showSearch?: boolean
}

export const HeaderMobile: FC<HeaderMobileProps> = ({
  onClickBag,
  modalOpened,
  address,
  onClickWillDelivery,
  isHome,
  showSearch,
}) => {
  const router = useRouter()
  const isSearchOpen = hasSearchParam(router)

  return (
    <Box display={['block', 'block', 'none']}>
      <StickySection modalOpened={modalOpened}>
        <Container>
          <Box
            as="nav"
            width="100%"
            display="flex"
            alignItems="center"
            height={theme.space.x56}
            px="x8"
          >
            <Grid
              as="ul"
              gridTemplateColumns="repeat(5, 1fr)"
              htmlAttrs={{ style: { width: '100%' } }}
            >
              <Grid gridColumn="1/2" as="li">
                <Menu isMobile />
              </Grid>
              <Grid gridColumn="2/3" as="li">
                {showSearch && <SearchButton />}
              </Grid>
              <Grid gridColumn="3/4" as="li">
                <Logo height="x32" />
              </Grid>
              <Grid gridColumn="4/5" as="li">
                <AccountButton isMobile />
              </Grid>
              <Grid gridColumn="5/6" as="li">
                <BagButton onClick={onClickBag} isMobile />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </StickySection>
      {isHome && !isSearchOpen && (
        <Box marginTop="x56">
          <WillDeliver
            address={address}
            onClickWillDeliver={onClickWillDelivery}
          />
        </Box>
      )}
      {isSearchOpen && (
        <Box marginTop="x56">
          <SearchInputMobile />
        </Box>
      )}
    </Box>
  )
}
