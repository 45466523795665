import React, { FC, useEffect, useRef, ChangeEvent, useState } from 'react'
import { useRouter } from 'next/router'
import {
  getSearchParam,
  hasSearchParam,
  removeSearchParam,
  updateSearchParam,
} from '@lojinha/helpers'
import { SearchInputMobileUI } from './ui'

export const SearchInputMobile: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const router = useRouter()
  const isSearchOpen = hasSearchParam(router)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const searchParamOnURL = getSearchParam(router)
    if (searchParamOnURL) {
      setSearchTerm(searchParamOnURL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const handleClose = () => {
    setSearchTerm('')
    removeSearchParam(router)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    updateSearchParam(router, e.target.value)
  }

  useEffect(() => {
    if (isSearchOpen) inputRef.current?.focus()
    else handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchOpen])

  return (
    <SearchInputMobileUI
      searchTerm={searchTerm}
      isSearchOpen={isSearchOpen}
      inputRef={inputRef}
      onChange={handleChange}
      onClose={handleClose}
    />
  )
}
