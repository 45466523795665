type Params = {
  w?: number
  h?: number
}

export const withParams = (url: string, params: Params = {}) => {
  const queryStart = url.includes('?') ? '&' : '?'

  const query = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .concat(['auto=format'])
    .join('&')

  return `${url}${queryStart}${query}`
}
