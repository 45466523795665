import { MarketableKind } from '@lojinha/palantir'
import { BagContentProps, BagGroupProps } from './types'

export const getToGroupKinds = ({ items }: BagContentProps) => {
  const kinds = items.reduce<MarketableKind[]>((acc, current) => {
    return acc.includes(current.item.kind) ? acc : [...acc, current.item.kind]
  }, [])

  return [...kinds]
    .sort((a, b) => a.localeCompare(b))
    .map<BagGroupProps>(kind => ({
      kind,
      items: items.filter(item => item.item.kind === kind),
    }))
}
