import React, { FC, ChangeEvent, RefObject } from 'react'
import { Box, Stack } from '@lojinha/design-system'
import { Close, Search } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'
import { t } from '../../dictionary'
import { CloseButton, Container, Input } from './styles'

type Props = {
  searchTerm: string
  isSearchOpen: boolean
  onClick: () => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
  inputRef: RefObject<HTMLInputElement>
}

export const SearchInputDesktopUI: FC<Props> = ({
  searchTerm,
  isSearchOpen,
  onClick,
  onChange,
  onClose,
  inputRef,
}) => (
  <Box
    display="flex"
    flex={1}
    paddingLeft="x8"
    htmlAttrs={{
      style: {
        position: 'relative',
        marginLeft: 'auto',
      },
    }}
  >
    <Container
      color="white"
      isActive={isSearchOpen}
      onClick={onClick}
      tabIndex={isSearchOpen ? -1 : 0}
      aria-label={t.search}
      {...testId('search-input-container')}
    >
      <Stack layout="horizontal" margin="x0">
        <Box display="flex" flex={1} order={1} alignItems="center">
          <Input
            ref={inputRef}
            onChange={onChange}
            value={searchTerm}
            tabIndex={isSearchOpen ? 0 : -1}
            type="text"
            placeholder={t.search}
            isActive={isSearchOpen}
            aria-label={t.searchAriaLabel}
          />
        </Box>
        <Box>
          <Search size="x3" color={isSearchOpen ? 'success' : 'black'} />
        </Box>
      </Stack>
    </Container>
    {isSearchOpen && (
      <Box
        marginLeft="x8"
        htmlAttrs={{
          style: { position: 'absolute', right: '1rem', top: '14px' },
        }}
      >
        <CloseButton
          onClick={onClose}
          {...testId('close-search-btn')}
          tabIndex={0}
        >
          <Close size="x3" color="black" />
        </CloseButton>
      </Box>
    )}
  </Box>
)
