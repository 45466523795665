import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'

export const useChat = () => {
  const { setChatLoading } = useLojinhaContext()

  beholder.watch('chatClosed', () => setChatLoading(false))

  const openChatEvent = () => beholder.shot('openChat', null)

  const onOpenChat = () => {
    setChatLoading(true)
    openChatEvent()
  }

  return {
    onOpenChat,
  }
}
