import React, { RefObject } from 'react'

import { Box } from '@lojinha/design-system'
import { capitalizeFirstLetter } from '@lojinha/helpers'

import { Menu } from '../../../../../types'
import { t } from '../../../../../dictionary'
import { MenuAnchor } from '../menu-anchor'
import { SubMenuList } from '../../styles'

type Props = {
  menu?: Menu | null
  onClick: (menu: Menu) => void
  isVisible?: boolean
  submenuItemRef?: RefObject<any>
  onSubmenuKeyboardNavigation?: (event: React.KeyboardEvent) => void
}

export const Submenu = ({
  menu,
  onClick,
  isVisible = true,
  submenuItemRef,
  onSubmenuKeyboardNavigation,
}: Props) => {
  if (!menu || !isVisible) return null

  return (
    <SubMenuList>
      {menu?.children?.map((submenu, index) => (
        <Box key={submenu.route} display={'flex'} as={'li'}>
          <MenuAnchor
            label={capitalizeFirstLetter(submenu.title)}
            route={submenu.route}
            isSecondary
            onClick={() => onClick(submenu)}
            htmlAttrs={{
              ...(index === 0 && { ref: submenuItemRef }),
              onKeyDown: onSubmenuKeyboardNavigation,
            }}
          />
        </Box>
      ))}
      {menu.route && (
        <Box key={menu.route} display={'flex'} as={'li'}>
          <MenuAnchor
            label={t.seeAll}
            route={menu.route}
            isSecondary
            onClick={() => onClick({ ...menu, title: t.seeAll })}
            htmlAttrs={{
              ...(!menu?.children?.length && { ref: submenuItemRef }),
              onKeyDown: onSubmenuKeyboardNavigation,
            }}
          />
        </Box>
      )}
    </SubMenuList>
  )
}
