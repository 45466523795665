import { ItemTag } from '@lojinha/palantir'
import { itemTagsContentTranslate } from '../../../../dictionary/pt-formatters'

export const filterBadges = ({
  badges,
  limitOfChars = 0,
}: {
  badges: ItemTag[]
  limitOfChars?: number
}) => {
  if (!limitOfChars) return badges

  const { filteredBadges } = badges.reduce(
    (
      acc: { filteredBadges: ItemTag[]; sumOfChars: number },
      badge: ItemTag
    ) => {
      const { filteredBadges, sumOfChars } = acc

      const content = itemTagsContentTranslate[badge.content]

      if (sumOfChars + content.length > limitOfChars) return acc

      return {
        filteredBadges: [...filteredBadges, badge],
        sumOfChars: sumOfChars + content.length,
      }
    },
    { filteredBadges: [], sumOfChars: 0 }
  )

  return filteredBadges
}
