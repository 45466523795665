import React, { FC } from 'react'
import { Box, Stack } from '@lojinha/design-system'
import { MenuItem } from '../menu-item'
import { Menu } from '../../../../../types'

type MenuListProps = {
  items: Menu[]
  onClick: (menu: Menu) => void
}

export const MenuList: FC<MenuListProps> = ({ items, onClick }) => {
  return (
    <Box paddingTop="x40">
      <Stack margin="x32">
        {items.map(item => (
          <MenuItem
            key={`${item.title}-${item.route}`}
            item={item}
            onClick={onClick}
          />
        ))}
      </Stack>
    </Box>
  )
}
