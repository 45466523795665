import React, { FC } from 'react'
import { Menu } from '../../../types'
import { MenuMobileUI } from './ui'
import { useMenuNavigation } from './hook'

type MenuMobileProps = {
  menu: Menu[]
}

export const MenuMobile: FC<MenuMobileProps> = ({ menu }) => {
  const {
    isMenuOpen,
    onCloseMenu,
    onOpenMenu,
    selectedSubMenu,
    handleClickMenu,
    goBackOnMenu,
  } = useMenuNavigation(menu)

  return (
    <MenuMobileUI
      onGoBack={goBackOnMenu}
      menuItems={menu}
      handleClickMenu={handleClickMenu}
      isMenuOpen={isMenuOpen}
      onCloseMenu={onCloseMenu}
      onOpenMenu={onOpenMenu}
      selectedSubMenu={selectedSubMenu}
    />
  )
}
