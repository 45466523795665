import { gql } from '@lojinha/palantir'

export const CART_SYNC = gql`
  mutation CartSync($input: CartSyncInput) {
    cartSync(input: $input) {
      cart {
        id
      }
    }
  }
`

export const FREE_SHIPPING_VALUE = gql`
  query FreeShippingValue {
    freeShippingValue
  }
`
