import React from 'react'
import { Stack, Text, theme } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import { BagGroupProps } from '../types'
import { BagItem } from '../item'
import { t } from '../../dictionary'

export const BagGroup = ({
  id,
  kind,
  items,
  hideEdition,
  hideAmountBox,
}: BagGroupProps) => {
  const loweredCaseKind = kind.toLowerCase() as 'kit' | 'meal' | 'product'

  return (
    <Stack
      key={id}
      as="section"
      margin="x16"
      htmlAttrs={{ style: { paddingTop: theme.space.x24 } }}
    >
      <Text {...testId('bag-group-title')}>{t[loweredCaseKind]}</Text>

      <Stack as="ul" layout="vertical" margin="x16">
        {items.map(({ item, quantity }) => (
          <BagItem
            key={item.id}
            id={item.id}
            name={item.name}
            imageUrl={item.imageUrl}
            kind={item.kind}
            price={item.price}
            sku={item.sku}
            quantity={quantity}
            mainProduct={item.mainProduct}
            priceFrom={item.priceFrom}
            subtitle={item.subtitle}
            hideEdition={hideEdition}
            hideAmountBox={hideAmountBox}
          />
        ))}
      </Stack>
    </Stack>
  )
}
