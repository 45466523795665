import {
  Coordinates,
  AddressSearchQuery,
  useApolloClient,
  CheckCoordinatesQuery,
  CheckCoordinatesQueryVariables,
  AddressSearchQueryVariables,
} from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'
import { MASKED_ZIP_CODE_LENGTH } from '../constants'
import { ADDRESS_SEARCH_QUERY, CHECK_COORDINATES } from './../queries'
import { UseZipCode, ZipCodeData } from './types'

export const useZipCode = (): UseZipCode => {
  const client = useApolloClient()

  const fetchAddressByZipCode = async ({
    zipCode,
    number,
  }: {
    zipCode: string
    number?: string
  }) => {
    if (!zipCode || zipCode.length < MASKED_ZIP_CODE_LENGTH) return {}

    try {
      const { data } = await client.query<
        AddressSearchQuery,
        AddressSearchQueryVariables
      >({
        query: ADDRESS_SEARCH_QUERY,
        variables: {
          input: {
            zipCode,
            number,
          },
        },
      })

      const { city, state } = data?.searchAddress || {}

      if (!city || !state) return {}

      return {
        city,
        coordinates: {
          latitude: data?.searchAddress?.coordinates?.latitude,
          longitude: data?.searchAddress?.coordinates?.longitude,
        },
        country: data?.searchAddress?.country,
        neighborhood: data?.searchAddress?.neighborhood,
        number: data?.searchAddress?.number || undefined,
        state,
        street: data?.searchAddress?.street,
        zipCode: data?.searchAddress?.zipCode,
      }
    } catch (err) {
      captureException(err)
      return Promise.resolve({})
    }
  }

  const validateCordinates = async (
    coordinates: Coordinates
  ): Promise<{ valid: boolean; nearestDistributionCenter?: string }> => {
    try {
      const { data } = await client.query<
        CheckCoordinatesQuery,
        CheckCoordinatesQueryVariables
      >({
        query: CHECK_COORDINATES,
        variables: {
          input: {
            coordinates,
          },
        },
      })

      const { valid, ecommDistributionCenter } = data.validateCoordinates

      return Promise.resolve({
        valid,
        nearestDistributionCenter: ecommDistributionCenter?.id,
      })
    } catch (err) {
      captureException(err)
      return Promise.resolve({ valid: false })
    }
  }

  const checkCoordinates = async (zipCodeData: ZipCodeData) => {
    const address = await fetchAddressByZipCode(zipCodeData)

    const { coordinates } = address

    if (!coordinates || !coordinates.latitude || !coordinates.longitude)
      return {
        valid: false,
      }

    const { valid, nearestDistributionCenter } = await validateCordinates({
      latitude: coordinates.latitude!,
      longitude: coordinates.longitude!,
    })

    return {
      valid,
      address,
      nearestDistributionCenter,
    }
  }

  return {
    checkCoordinates,
    fetchAddressByZipCode,
    validateCordinates,
  }
}
