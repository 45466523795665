import { Menu } from '../../types'

type MenuStrapi = Menu & {
  visibleAtDistributionCenters?: string[]
  children?: MenuStrapi[]
  // isMainMenu: boolean // will be filtered by graphql / only on main level
  // isActive: boolean // will be filtered by graphql on both levels
}

const MENU_DATA: MenuStrapi[] = [
  {
    title: 'Kits',
    route: '/kits',
    isNew: false,
    image: {
      soloImage: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-kits.jpg',
        accessibilityLabel:
          'Mesa com dois pratos com comida e um copo de água, mãos de uma pessoa comendo de garfo e faca',
      },
    },
  },
  {
    title: 'Refeições congeladas',
    route: '/refeicoes-congeladas',
    isNew: false,
    children: [
      {
        title: 'Pra compartilhar',
        route: '/porcoes/pra-compartilhar',
        isNew: false,
        visibleAtDistributionCenters: [
          '60c7642aa1dfc196eafa9790', // saude
          '60c76421a1dfc196eafa9358', // rio comprido
        ],
      },
      {
        title: 'Pratos combinados',
        route: '/pratos',
        isNew: false,
      },
      {
        title: 'Porções principais',
        route: '/porcoes/principal',
        isNew: false,
      },
      {
        title: 'Acompanhamentos',
        route: '/porcoes/acompanhamentos',
        isNew: false,
      },
      {
        title: 'Pratos caseiros',
        route: '/pratos/caseiros',
        isNew: false,
        visibleAtDistributionCenters: [
          '60c7642aa1dfc196eafa9790', // saude
          '60c76421a1dfc196eafa9358', // rio comprido
          '5c640cac22fad440b21837db', // curitiba
          '5b1eac44e3767bfdd4b7b2d8', // bh
          '5c1bd7c8b9760167cc09da69', // campinas
          '5cc3797b69c8c48d7a767db5', // brasilia
          '5cd2e32769c8c48d7ac0c301', // poa
          '5cf847e37daa4f84ffdb9644', // sorocaba
          '5cf847c67daa4f84ffdb93fd', // sjc
          // Prováveis próximas atualizações >
          // '5cf846797daa4f84ffdb7bde', // jundiai
          // '5cc9a88769c8c48d7afdd772', // ribeirao
        ],
      },
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-congelados-2.jpg',
        accessibilityLabel: 'Dois pratos com refeições completas prontas',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-congelados.jpg',
        accessibilityLabel:
          'Três saquinhos com comida congelada da liv up, um verde, um vermelho e um azul',
      },
    },
  },
  {
    title: 'Hortifruti',
    route: '/quitanda/hortifruti',
    isNew: false,
    visibleAtDistributionCenters: [
      '60c7642aa1dfc196eafa9790', // saude
    ],
    children: [
      {
        title: 'Sugestões',
        route: '/quitanda/selecao-de-organicos',
        isNew: false,
      },
      {
        title: 'Frutas',
        route: '/quitanda/frutas',
        isNew: false,
      },
      {
        title: 'Saladas prontas',
        route: '/quitanda/saladas',
        isNew: false,
      },
      {
        title: 'Verduras e temperos',
        route: '/quitanda/verduras-e-temperos',
        isNew: false,
      },
      {
        title: 'Legumes',
        route: '/quitanda/legumes',
        isNew: false,
      },
      {
        title: 'Ovos e cogumelos',
        route: '/quitanda/ovos-e-cogumelos',
        isNew: false,
      },
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-hortifruti-2.jpg',
        accessibilityLabel:
          'Uma salada de folhas diversas, cenoura ralada e tomates grape',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-hortifruti.jpg',
        accessibilityLabel: 'Cacho de bananas, mangas e laranjas',
      },
    },
  },
  {
    title: 'Laticínios',
    route: '/quitanda/laticinios2',
    isNew: false,
    visibleAtDistributionCenters: [
      '60c7642aa1dfc196eafa9790', // saude
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-laticinios.jpg',
        accessibilityLabel: 'Um litro transparente de leite sendo despejado',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-laticinios-2.jpg',
        accessibilityLabel:
          'Fatias de mussarela enroladas e dispostas em uma pirâmide',
      },
    },
  },
  {
    title: 'Açougue e peixaria',
    route: '/quitanda/acougue-peixaria',
    isNew: false,
    visibleAtDistributionCenters: [
      '60c7642aa1dfc196eafa9790', // saude
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-acougue.jpg',
        accessibilityLabel:
          'Tábua com filés de carne e limões cortados ao lado',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-acougue-2.jpg',
        accessibilityLabel: 'Um camarão limpo',
      },
    },
  },
  {
    title: 'Padaria e doceria',
    isNew: false,
    children: [
      {
        title: 'Pães e salgados',
        route: '/lanches-e-bebidas/lanches-e-matinais',
        isNew: false,
      },
      {
        title: 'Doces',
        route: '/lanches-e-bebidas/doces',
        isNew: false,
      },
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-padaria.jpg',
        accessibilityLabel: 'Oito croissants em uma superfície',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-padaria-2.jpg',
        accessibilityLabel:
          'Muffin de chocolate aberto com uma mordida, mostrando o interior',
      },
    },
  },
  {
    title: 'Mercearia',
    route: '/quitanda/mercearia2',
    isNew: false,
    visibleAtDistributionCenters: [
      '60c7642aa1dfc196eafa9790', // saude
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-mercearia.jpg',
        accessibilityLabel: 'Um vidro de azeite sendo despejado',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-mercearia-2.jpg',
        accessibilityLabel: 'Uma colher de sopa com açúcar de coco',
      },
    },
  },
  {
    title: 'Bebidas',
    route: '/porcoes/bebidas',
    isNew: false,
    children: [
      {
        title: 'Bebidas não alcoólicas',
        route: '/porcoes/bebidas-nao-alcoolicas',
        isNew: false,
        visibleAtDistributionCenters: ['60c7642aa1dfc196eafa9790'], // saude
      },
      {
        title: 'Bebidas alcoólicas',
        route: '/porcoes/bebidas-alcoolicas',
        isNew: false,
        visibleAtDistributionCenters: ['60c7642aa1dfc196eafa9790'], // saude
      },
    ],
    image: {
      imageTop: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-bebidas.jpg',
        accessibilityLabel:
          'Um litro transparente de chá preto com limão sendo despejado',
      },
      imageBottom: {
        url:
          'https://s3.sa-east-1.amazonaws.com/static.livup.us/lojinha/header/menu/categoria-bebidas-2.jpg',
        accessibilityLabel: 'Uma garrafa de vinho sendo despejado em uma taça',
      },
    },
  },
]

const filterDistributionCenters = (
  menuStrapi: MenuStrapi[],
  distributionCenterId: string | undefined
): Menu[] =>
  menuStrapi
    .filter(
      menuItem =>
        distributionCenterId === undefined ||
        menuItem.visibleAtDistributionCenters === undefined ||
        menuItem.visibleAtDistributionCenters.length === 0 ||
        menuItem.visibleAtDistributionCenters.includes(distributionCenterId)
    )
    .map(menuItem => ({
      title: menuItem.title,
      route: menuItem.route,
      isNew: menuItem.isNew,
      children:
        menuItem.children &&
        filterDistributionCenters(menuItem.children, distributionCenterId),
      image: menuItem.image,
    }))

// TODO: replace this function by graphql query
export const getMenu = (distributionCenterId?: string): Menu[] =>
  filterDistributionCenters(MENU_DATA, distributionCenterId)
