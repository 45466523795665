import { useLojinhaContext } from '@lojinha/context'
import { useEffect, useState } from 'react'
import { getMenu } from '../menu'

export const useMenu = () => {
  const { centerId, isAuthenticated, isContextLoaded } = useLojinhaContext()
  const [menu, setMenu] = useState(getMenu(centerId))
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const isLoaded = isAuthenticated && isContextLoaded
    if (!isAuthenticated || isLoaded) setIsLoading(false)
  }, [isAuthenticated, isContextLoaded, centerId])

  useEffect(() => {
    setMenu(getMenu(centerId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId, isAuthenticated])

  return {
    menu,
    isAuthenticated,
    isLoading,
  }
}
