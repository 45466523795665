import React from 'react'
import {
  AlertTag,
  AlertTagVariants,
  AlertTagIcons,
  Text,
} from '@lojinha/design-system'
import { ColorProps } from '@lojinha/design-system/src/theme'
import { BagFeedbacks } from '@lojinha/context/src/types'
import { t } from './../dictionary'

export type BagFeedbackProps = {
  removedItems?: string[]
  feedback?: BagFeedbacks
}

export const BagFeedback = ({ removedItems, feedback }: BagFeedbackProps) => {
  if (!feedback) return null

  const variants = {
    [BagFeedbacks.allItemsAdded]: {
      content: t.feedbacks.allItemsAdded,
      variant: AlertTagVariants.primary,
      icon: AlertTagIcons.bag,
      iconColor: 'success',
    },
    [BagFeedbacks.allItemsRemoved]: {
      content: (
        <Text isBold color="white">
          {t.feedbacks.allUnavailableItems}
        </Text>
      ),
      variant: AlertTagVariants.error,
      icon: AlertTagIcons.alert,
      iconColor: 'white',
    },
    [BagFeedbacks.someItemsRemoved]: {
      content: (
        <ul>
          <Text isBold>{t.feedbacks.someUnavailableItems}</Text>
          {removedItems?.map((item, i) => (
            <Text
              key={i}
              as="li"
              htmlAttrs={{
                style: { listStyleType: 'none' },
                'aria-label': `, ${item}`,
              }}
            >
              - {item}
            </Text>
          ))}
        </ul>
      ),
      variant: AlertTagVariants.softAlert,
      icon: AlertTagIcons.alert,
      iconColor: 'black',
    },
    [BagFeedbacks.ageRestrictedItemsRemoved]: {
      content: <Text textSize="x14">{t.feedbacks.ageRestrictedItems}</Text>,
      icon: AlertTagIcons.alert,
      iconColor: 'black',
      variant: AlertTagVariants.softAlert,
    },
  }

  return (
    <AlertTag
      content={variants[feedback].content}
      variant={variants[feedback].variant}
      icon={variants[feedback].icon}
      iconColor={variants[feedback].iconColor as ColorProps}
    />
  )
}
