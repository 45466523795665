import React from 'react'
import { useRouter } from 'next/router'
import { getSearchParam } from '@lojinha/helpers'
import { useSearch } from './hook'
import { LoadingSearch } from './loading-state'
import { SearchResults } from './results'
import { EmptySearch } from './empty-state'
import { PreSearch } from './pre-search'

export const Search = () => {
  const router = useRouter()
  const searchTerm = getSearchParam(router)
  const { results, totalCount, hasResults, loading, debouncing } = useSearch(
    searchTerm
  )

  const empty = searchTerm && !hasResults && !debouncing

  if (loading) return <LoadingSearch />

  if (empty) return <EmptySearch searchTerm={searchTerm} />

  if (hasResults)
    return (
      <SearchResults
        term={searchTerm}
        results={results}
        totalCount={totalCount}
      />
    )

  return <PreSearch />
}
