import { Box, Center, Grid, Skeleton, theme } from '@lojinha/design-system'
import React, { FC } from 'react'

export const LoadingSearch: FC = () => {
  const results = [1, 1, 1, 1, 1, 1, 1, 1]

  return (
    <>
      <Center>
        <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
          <Grid gridColumn={['2 / 24', '2 / 12']}>
            <Box marginTop={['x24', 'x56']}>
              {/* buscando por: "" */}
              <Box marginBottom="x4" width={[151, 203]} height={24}>
                <Skeleton />
              </Box>
              {/* itens encontrados */}
              <Box marginBottom="x16" width={119} height={18}>
                <Skeleton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Center>

      <Box
        display="flex"
        paddingTop={['x40', 'x80']}
        paddingBottom={['x24', 'x20']}
      >
        <Center htmlAttrs={{ style: { width: '100%' } }}>
          <Grid
            gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}
            htmlAttrs={{ style: { width: '100%' } }}
          >
            <Grid gridColumn={['2 / 24', '2 / 12']}>
              <Grid
                gridTemplateColumns={[
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                gridGap="x16"
                gridRowGap="x32"
              >
                {results.map((_, i) => (
                  <Box key={i} height={[303, 408]}>
                    <Skeleton
                      htmlAttrs={{
                        style: {
                          borderRadius: theme.borderRadius.x8,
                        },
                      }}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Center>
      </Box>
    </>
  )
}
