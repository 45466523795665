import React from 'react'
import { Address } from '@lojinha/location'
import { Stack, Text, AlertTag, AlertTagIcons } from '@lojinha/design-system'
import { t } from '../../../dictionary'

type Props = {
  address: Address
}

enum AlertTagVariants {
  info = 'info',
}

export const InsideDeliveryArea = ({ address }: Props) => {
  return (
    <Stack margin="x24" layout="vertical" alignItems="center">
      <AlertTag
        content={t.singleAddress(address)}
        icon={AlertTagIcons.check}
        variant={AlertTagVariants.info}
      />
      <Text textAlign="center">{t.insideDeliveryArea}</Text>
    </Stack>
  )
}
