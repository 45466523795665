import styled from 'styled-components'
import { tabNavigationStyle } from '@lojinha/design-system'

export const Container = styled.button<{ isActive?: boolean }>(
  ({ isActive, theme }) => `
    margin-left: auto;
    cursor: pointer;
    border: none;
    width: 100%;
    max-width: ${isActive ? '600px' : '125px'};
    transition: max-width 0.3s ease-in, background-color 0.2s;
    padding: ${theme.space.x12} ${theme.space.x24};
    border-radius: ${theme.space.x48};
    &:hover,
    &:active,
    &:focus-visible {
      background: ${theme.colors.gray100};
    }
    background-color: ${isActive ? theme.colors.gray100 : theme.colors.white};
`,
  tabNavigationStyle
)

export const Input = styled.input<{ isActive?: boolean }>(
  ({ isActive, theme }) => `
    font-size: ${theme.fontSizes.x14};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.black};
    margin-left: ${theme.space.x8};
    border: none;
    width: 100%;
    background-color: ${theme.colors.transparent};

    ::placeholder {
      color: ${isActive ? theme.colors.gray700 : theme.colors.black};
      transition: color 0.3s;
    }

    &:focus::placeholder {
      color: ${theme.colors.gray700};
    }
`
)

export const CloseButton = styled.button(
  ({ theme }) => `
    display: flex;
    cursor: pointer;
    background-color: transparent;
    border: none;
    svg path {
      fill: ${theme.colors.gray700};
    }
    &:hover {
      svg path {
        fill: ${theme.colors.black};
      }
    }
`,
  tabNavigationStyle
)
