import React, { FC } from 'react'
import { useMenu } from './hook'
import { MenuDesktop } from './menu-desktop'
import { MenuMobile } from './menu-mobile'

type MenuProps = {
  isMobile: boolean
  isLoading?: boolean
}

export const Menu: FC<MenuProps> = ({ isMobile, isLoading = false }) => {
  const { menu } = useMenu()
  return isMobile ? (
    <MenuMobile menu={menu} />
  ) : (
    <MenuDesktop menu={menu} isLoading={isLoading} />
  )
}
