import React, { FC } from 'react'
import { useHideOn } from './helpers/hideOn'
import { HeaderDesktop } from './modules/header-desktop'
import { HeaderMobile } from './modules/header-mobile'

type HeaderProps = {
  onClickBag: () => void
  onClickWillDelivery: () => void
  modalOpened?: boolean
  address?: string
  isHome: boolean
  showSearch?: boolean
}

export const Header: FC<HeaderProps> = ({
  onClickBag,
  onClickWillDelivery,
  modalOpened,
  address,
  isHome,
  showSearch,
}) => {
  const { hideOn } = useHideOn()

  return (
    <>
      {hideOn !== 'mobile' && (
        <HeaderMobile
          onClickBag={onClickBag}
          address={address}
          onClickWillDelivery={onClickWillDelivery}
          modalOpened={modalOpened}
          isHome={isHome}
          showSearch={showSearch}
        />
      )}
      {hideOn !== 'desktop' && (
        <HeaderDesktop
          onClickBag={onClickBag}
          onClickWillDelivery={onClickWillDelivery}
          address={address}
          modalOpened={modalOpened}
          showSearch={showSearch}
        />
      )}
    </>
  )
}
