import { getZipCodeAndNumberByCoordinates } from '@lojinha/location'
import { captureException } from '@lojinha/sentry'
import { useState } from 'react'

export const useGeolocationZipCode = () => {
  const [zipCode, setZipCode] = useState('')

  const getGeolocation = async () => {
    try {
      navigator.geolocation.getCurrentPosition(async ({ coords }) => {
        const {
          zipCode: geolocationZipCode,
        } = await getZipCodeAndNumberByCoordinates(coords)
        geolocationZipCode && setZipCode(geolocationZipCode)
      })
    } catch (err) {
      captureException(err)
    }
  }

  return {
    zipCode,
    getGeolocation,
  }
}
