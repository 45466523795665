import React from 'react'
import { Stack } from '@lojinha/design-system'
import { BagContentProps } from './types'
import { BagGroup } from './group'
import { getToGroupKinds } from './logic'

export const BagContent = ({ items, hideEdition = false }: BagContentProps) => {
  const groupItems = getToGroupKinds({ items: items })

  return (
    <Stack layout="vertical" margin="x0">
      {groupItems.map(group => (
        <BagGroup
          items={group.items}
          kind={group.kind}
          key={group.kind}
          hideEdition={hideEdition}
        />
      ))}
    </Stack>
  )
}
