import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const BankSlip: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5H4V20H2V5ZM12 5H14V18H12V5ZM18 5H16V18H18V5ZM20 5H22V20H20V5ZM10 5H6V18H10V5Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

BankSlip.defaultProps = {
  color: 'black',
  size: 'x3',
}
