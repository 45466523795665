import { useState } from 'react'
import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import {
  BagFeedbacks,
  BagProps,
  CartItemGroup,
} from '@lojinha/context/src/types'
import { getLocalStorage, setLocalStorage } from '@lojinha/helpers'
import {
  NumberOfOrdersQuery,
  RemoveUnavailableItemsFromCartMutation,
  RemoveUnavailableItemsMutationVariables,
  useApolloClient,
} from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'
import { useGoToCheckout } from '@lojinha/bag'
import {
  UpdateCartEvents,
  UpdateCartProps,
  UpdateItemOnBagType,
} from '../types'
import { useWindowProperties } from '../../../helpers/useWindowProperties'
import {
  REMOVE_UNAVAILABLE_ITEMS_MUTATION,
  NUMBER_OF_ORDERS_QUERY,
} from './queries'

const sendAddToCartEvent = async ({
  item,
  addend,
  cartItems,
  isFirstBuy,
  eventList,
  positionInList,
  cartSubtotal,
}: UpdateCartEvents) => {
  const action = addend === 1 ? 'add' : 'remove'
  beholder.shot('addToCart', {
    action,
    list: eventList,
    positionInList,
    isFirstBuy,
    item,
    cartItems,
    cartSubtotal,
  })

  const isCartCreated = cartItems.length === 0 && action === 'add'

  isCartCreated &&
    beholder.shot('cartCreated', {
      list: eventList,
      positionInList,
      isFirstBuy,
      item,
      cartItems,
    })
}

// TODO: move to @lojinha/helpers
const createBagContent = (items: CartItemGroup[]) => {
  const updateBagValues = items?.reduce(
    (
      acc: {
        bagSubtotal: number
        bagPriceFrom: number
        bagQuantity: number
      },
      bagItem: CartItemGroup
    ) => {
      const { priceFrom: bagItemPriceFrom, price: bagItemPrice } = bagItem.item

      const itemPriceFrom =
        bagItemPriceFrom && bagItemPriceFrom > 0
          ? bagItemPriceFrom
          : bagItemPrice

      return {
        bagSubtotal: bagItemPrice * bagItem.quantity + acc.bagSubtotal,
        bagPriceFrom: itemPriceFrom * bagItem.quantity + acc.bagPriceFrom,
        bagQuantity: bagItem.quantity + acc.bagQuantity,
      }
    },
    {
      bagSubtotal: 0,
      bagPriceFrom: 0,
      bagQuantity: 0,
    }
  )

  const { bagQuantity, bagSubtotal, bagPriceFrom } = updateBagValues

  return {
    cartItems: items,
    quantity: bagQuantity,
    subtotal: bagSubtotal,
    subtotalPriceFrom: bagPriceFrom,
    createdAt: new Date(),
  } as BagProps
}

export const useUpdateCart = ({
  eventList,
  positionInList,
}: UpdateCartProps) => {
  const {
    bag,
    setBag,
    isAuthenticated,
    centerId,
    setBagOpen,
    setBagFeedback,
    setRemovedItems,
  } = useLojinhaContext()

  const localStorageBag = JSON.parse(getLocalStorage('localBag') || '{}')
  const bagItems = localStorageBag.cartItems ?? []

  const [isLoading, setIsLoading] = useState(false)
  const [updateCartError, setUpdateCartError] = useState(false)

  const client = useApolloClient()

  const { handleCartSync } = useGoToCheckout()

  const { isMobile } = useWindowProperties()

  const getNumberOfOrders = async () => {
    try {
      if (!isAuthenticated) return 0

      const { data } = await client.query<NumberOfOrdersQuery>({
        query: NUMBER_OF_ORDERS_QUERY,
        fetchPolicy: 'cache-first',
      })

      return data.viewer.user?.sales.totalCount ?? 0
    } catch {
      return 0
    }
  }

  const updateLocalBag = (bagContent: BagProps) => {
    setLocalStorage('localBag', JSON.stringify(bagContent))
    setBag(bagContent)
  }

  const updateItemOnBag = async ({ item, addend }: UpdateItemOnBagType) => {
    setIsLoading(true)

    const itemChanged = {
      item,
      quantity: item.quantity + addend,
      id: item.id,
    }

    const hasItemOnBag = bagItems?.filter(
      (bagItem: CartItemGroup) => bagItem.id === itemChanged.id
    ).length

    const updateBagItems = hasItemOnBag
      ? bagItems
          .map((bagItem: CartItemGroup) => {
            if (bagItem.id === itemChanged.id) {
              return {
                ...itemChanged,
                quantity: bagItem.quantity + addend,
              }
            }
            return bagItem
          })
          .filter((bagItem: CartItemGroup) => bagItem.quantity !== 0)
      : [...bagItems, itemChanged]

    const bagContent = createBagContent(updateBagItems)
    updateLocalBag(bagContent)

    const isFirstAdd = bagContent.quantity === 1 && addend === 1
    isFirstAdd && !isMobile && setBagOpen(true)

    const numberOfOrders = await getNumberOfOrders()

    sendAddToCartEvent({
      eventList,
      positionInList,
      item: {
        id: item.id,
        category: item.kind,
        name: item.name,
        price: item.price,
        quantity: item.quantity ?? 0,
      },
      addend,
      isFirstBuy: !!numberOfOrders,
      cartItems: bagItems
        ? bagItems.map((bagItem: CartItemGroup) => ({
            id: bagItem.item.id,
            quantity: bagItem.quantity,
            price: bagItem.item.price,
          }))
        : [],
      cartSubtotal: bagContent.subtotal,
    })

    setIsLoading(false)
  }

  const removeUnavailableItems = async (
    cartId?: string
  ): Promise<{ removedItems?: string[]; cartItems?: CartItemGroup[] }> => {
    if (!centerId || !cartId) return {}

    try {
      const { data } = await client.mutate<
        RemoveUnavailableItemsFromCartMutation,
        RemoveUnavailableItemsMutationVariables
      >({
        mutation: REMOVE_UNAVAILABLE_ITEMS_MUTATION,
        variables: {
          cartId,
          distributionCenterId: centerId,
        },
      })

      const removeUnavailableData = data?.removeUnavailableItemsFromCart

      if (!removeUnavailableData) throw Error

      const { cart } = removeUnavailableData

      const cartItems = cart?.items.nodes.map(item => {
        return {
          id: item.item.id,
          item: item.item,
          quantity: item.quantity,
        }
      })

      const removedItems = removeUnavailableData.removedItems.map(
        removeItem => removeItem.name
      )

      return {
        removedItems,
        cartItems,
      }
    } catch (err) {
      setUpdateCartError(true)
      captureException(
        new Error(`Failed to remove unavailable items from cart, ${err}`)
      )

      return {}
    }
  }

  const addNewProductsToBagGroup = (newProducts: CartItemGroup[]) =>
    bagItems.reduce((saleItems: CartItemGroup[], saleItem: CartItemGroup) => {
      const hasItemOnBag = newProducts.filter(
        itemChanged => itemChanged.id === saleItem.id
      ).length

      if (!hasItemOnBag) return [saleItem, ...saleItems]
      return saleItems
    }, newProducts)

  const updateItemsOnBag = async ({
    products,
  }: {
    products: CartItemGroup[]
  }) => {
    setIsLoading(true)

    const productsUpdated: CartItemGroup[] = products.map(item => {
      const { id } = item.item

      const itemOnBag = bagItems?.filter(
        (bagItem: CartItemGroup) => bagItem.id === id
      )

      const itemQuantity = itemOnBag?.length
        ? itemOnBag[0].quantity + item.quantity
        : item.quantity

      return {
        id,
        item: item.item,
        quantity: itemQuantity,
      }
    })

    const bagContent = createBagContent(productsUpdated)

    try {
      const cartIdCreated = await handleCartSync(bagContent)
      if (!cartIdCreated) throw Error

      const removeUnavailablesData = await removeUnavailableItems(cartIdCreated)

      const bagWithoutUnavailableItems = removeUnavailablesData?.cartItems

      const removedItems = removeUnavailablesData?.removedItems
      const hasAvailableItems =
        bagWithoutUnavailableItems && bagWithoutUnavailableItems.length

      const allItemsHaveBeenRemoved = !bagWithoutUnavailableItems?.filter(
        item =>
          products.filter(saleProduct => saleProduct.item.id === item.id).length
      ).length

      if (!hasAvailableItems || allItemsHaveBeenRemoved) {
        setBagFeedback(BagFeedbacks.allItemsRemoved)
      } else if (removedItems && removedItems.length) {
        setBagFeedback(BagFeedbacks.someItemsRemoved)
        setRemovedItems(removedItems)
      } else {
        setBagFeedback(BagFeedbacks.allItemsAdded)
      }

      const bagItemsUpdated = addNewProductsToBagGroup(
        bagWithoutUnavailableItems ?? []
      )

      const bagContentUpdated = createBagContent(bagItemsUpdated)

      updateLocalBag(bagContentUpdated)

      setIsLoading(false)
      setBagOpen(true)
    } catch {
      setUpdateCartError(true)
      setIsLoading(false)
      captureException(new Error('Failed to add purchase products on cart'))
    }
  }

  const getToItemQuantity = (itemId: string) =>
    bag?.cartItems.find(bagItem => bagItem.item.id === itemId)?.quantity ?? 0

  return {
    getToItemQuantity,
    isLoading,
    updateItemOnBag,
    updateItemsOnBag,
    updateCartError,
  }
}
