import React from 'react'
import { Box, Text, theme } from '@lojinha/design-system'
import { t } from '../dictionary'

export const BagHeader = ({ totalItems }: { totalItems: number }) => (
  <Box
    as="p"
    display="flex"
    alignItems="center"
    minHeight={theme.space.x32}
    htmlAttrs={{ 'aria-label': t.yourBagCountAriaLabel }}
  >
    <Text as="span">
      {`${t.yourBag}  `}
      <Text isBold as="strong">
        {t.productsCount(totalItems)}
      </Text>
    </Text>
  </Box>
)
