import React, { useEffect, useState } from 'react'
import { Box, Center, Grid } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import { Menu, MenuNavigationProps } from '../../../../../types'
import { MenuAnchor } from '../menu-anchor'
import { Submenu } from '../submenu'
import {
  MenuNavigationContainer,
  MenuNavigationContent,
  MenuNavigationDrawer,
  MenuBannerContainer,
  MenuBannerContent,
} from '../../styles'
import { DesktopMenuImage } from '../../../components/menu-image'

export const MenuNavigation = ({
  isMenuOpen,
  onCloseMenu,
  menuItems,
  handleClickMenu,
  selectedSubMenu,
  setSelectedSubMenu,
  categoryItemRef,
  submenuItemRef,
  onPanelKeyboardNavigation,
  onCategoryKeyboardNavigation,
  onSubmenuKeyboardNavigation,
}: Omit<MenuNavigationProps, 'onOpenMenu'>) => {
  const [enabledSubMenuAnimation, setEnabledAnimation] = useState(false)

  useEffect(() => {
    const timeToAnimation = setTimeout(() => setEnabledAnimation(true), 100)
    return () => {
      clearTimeout(timeToAnimation)
    }
  }, [selectedSubMenu, enabledSubMenuAnimation])

  const onEnabledSubmenu = (menu: Menu) => {
    if (menu === selectedSubMenu) return
    setSelectedSubMenu && setSelectedSubMenu(menu)
    setEnabledAnimation(false)
  }

  return (
    <MenuNavigationDrawer
      enabled={isMenuOpen}
      aria-hidden={!isMenuOpen}
      onKeyDown={onPanelKeyboardNavigation}
      {...testId('menu-desktop-navigation')}
    >
      <MenuNavigationContainer enabled={isMenuOpen}>
        <MenuNavigationContent onMouseLeave={onCloseMenu} enabled={isMenuOpen}>
          <Center
            htmlAttrs={{
              style: {
                height: '100%',
                display: 'flex',
              },
            }}
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              htmlAttrs={{
                style: { width: '100%' },
              }}
            >
              <Grid gridColumn="2 / 12">
                <Grid gridTemplateColumns={'repeat(10, 1fr)'}>
                  <Grid gridColumn={'1/3'}>
                    <Box
                      borderWidth={0}
                      borderStyle={'solid'}
                      borderRightWidth={1}
                      borderColor={'gray100'}
                      py="x40"
                    >
                      {menuItems.map((currentItem: Menu, index) => {
                        const { route, title } = currentItem

                        const itemFocused = !!selectedSubMenu?.title
                          ? title === selectedSubMenu?.title
                          : index === 0

                        return (
                          <MenuAnchor
                            key={route || title}
                            label={title}
                            htmlAttrs={{
                              onFocus: () => onEnabledSubmenu(currentItem),
                              onMouseOver: () => onEnabledSubmenu(currentItem),
                              as: 'li',
                              tabIndex: isMenuOpen ? 0 : -1,
                              ...(itemFocused && {
                                ref: categoryItemRef,
                              }),
                              'aria-expanded': itemFocused,
                              onKeyDown: onCategoryKeyboardNavigation,
                            }}
                          />
                        )
                      })}
                    </Box>
                  </Grid>
                  <Grid gridColumn={'3/5'}>
                    <Box py="x40">
                      <Submenu
                        menu={selectedSubMenu}
                        onClick={handleClickMenu}
                        isVisible={enabledSubMenuAnimation}
                        submenuItemRef={submenuItemRef}
                        onSubmenuKeyboardNavigation={
                          onSubmenuKeyboardNavigation
                        }
                      />
                    </Box>
                  </Grid>
                  <MenuBannerContainer
                    gridColumn={'8/11'}
                    isVisible={enabledSubMenuAnimation}
                  >
                    {enabledSubMenuAnimation && selectedSubMenu?.image && (
                      <MenuBannerContent py="x40">
                        <DesktopMenuImage image={selectedSubMenu.image} />
                      </MenuBannerContent>
                    )}
                  </MenuBannerContainer>
                </Grid>
              </Grid>
            </Grid>
          </Center>
        </MenuNavigationContent>
      </MenuNavigationContainer>
    </MenuNavigationDrawer>
  )
}
