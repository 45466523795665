import { LocationPointer } from '@lojinha/design-system/src/icons'
import { AddressData, useSaveAddress } from '@lojinha/location'
import React, { FC } from 'react'
import { t } from '../../dictionary'
import { AddressListDropdown } from '../address-list-dropdown'
import { HeaderDesktopButton } from '../header-desktop-button'
import { DropdownAnchor } from './components/dropdown-anchor'
import { WillDeliveryLoading } from './components/loading'

type Props = {
  onClickWillDelivery: () => void
  address?: string
  isLoading?: boolean
}

export const WillDelivery: FC<Props> = ({
  onClickWillDelivery,
  address,
  isLoading,
}) => {
  const { onSubmit } = useSaveAddress()

  if (isLoading) return <WillDeliveryLoading />

  return address ? (
    <AddressListDropdown
      address={address}
      onClickWillDelivery={onClickWillDelivery}
      onSelectAddress={selectedAddress =>
        onSubmit(selectedAddress as AddressData)
      }
      anchorElement={<DropdownAnchor currentAddress={address} />}
    />
  ) : (
    <HeaderDesktopButton
      mainText={t.deliveryAddress}
      icon={<LocationPointer color="black" />}
      onClick={onClickWillDelivery}
    />
  )
}
