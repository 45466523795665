import {
  Box,
  Grid,
  Stack,
  tabNavigationStyle,
  Text,
  theme,
} from '@lojinha/design-system'
import { numberToCurrency, testId } from '@lojinha/helpers'
import React, { FC } from 'react'
import styled from 'styled-components'
import { AddToCartButton } from '@lojinha/vitrine/src/components/add-to-cart-button'
import { t } from '../../dictionary'
import { BagItemProps } from '../types'
import { ItemImage } from './image'

const TextEllipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Container = styled.li`
  ${tabNavigationStyle};
  list-style: none;

  &:after {
    content: '';
    display: block;
    height: 1px;
    padding-top: ${theme.space.x16};
  }

  &:before {
    content: none;
  }
`

export const BagItem: FC<BagItemProps> = ({
  id,
  imageUrl,
  name,
  price,
  priceFrom,
  kind,
  sku,
  quantity,
  mainProduct,
  subtitle,
  hideEdition,
  hideAmountBox,
  noWrap,
  eventList,
  positionInList,
}) => {
  return (
    <Container
      aria-label={t.bagItemAriaLabel({
        title: mainProduct ? mainProduct : name,
        description: subtitle,
        price,
        priceFrom,
        quantity,
      })}
      key={`item-${id}`}
      tabIndex={0}
      role="listitem"
      {...testId('bag-item')}
    >
      <Stack layout="horizontal" margin="x16">
        <ItemImage ariaLabel={name} url={imageUrl} />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
          height={theme.space.x96}
          minWidth="0"
        >
          <Box marginBottom="x8">
            {noWrap ? (
              <Text textSize={['x14', 'x2']}>{mainProduct ?? name}</Text>
            ) : (
              <TextEllipsis textSize={['x14', 'x2']}>
                {mainProduct ?? name}
              </TextEllipsis>
            )}
            {subtitle && (
              <TextEllipsis textSize="x1" color="gray700">
                {subtitle}
              </TextEllipsis>
            )}
          </Box>
          <Grid
            gridTemplateColumns="repeat(2, 1fr)"
            htmlAttrs={{ style: { alignItems: 'end' } }}
          >
            <Box paddingBottom="x8">
              {!!priceFrom && priceFrom > price && (
                <Text
                  color="gray700"
                  textDecoration="line-through"
                  textSize={['x14', 'x2']}
                  aria-hidden
                >
                  {numberToCurrency(priceFrom)}
                </Text>
              )}
              <Text textSize={['x14', 'x2']} aria-hidden>
                {numberToCurrency(price)}
              </Text>
            </Box>
            {hideEdition ? (
              <Box paddingBottom="x8">
                <Text color="gray700" aria-hidden>
                  {t.quantity(quantity)}
                </Text>
              </Box>
            ) : (
              !hideAmountBox && (
                <AddToCartButton
                  refPage="Cart"
                  isSmall
                  eventList={eventList}
                  positionInList={positionInList}
                  item={{
                    id,
                    imageUrl,
                    kind,
                    name,
                    price,
                    quantity,
                    priceFrom,
                    sku,
                    subtitle,
                  }}
                />
              )
            )}
          </Grid>
        </Box>
      </Stack>
    </Container>
  )
}
