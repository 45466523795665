import React, { FC } from 'react'
import { Box, Divider, Stack, Text } from '@lojinha/design-system'
import { ChevronRight } from '@lojinha/design-system/src/icons'
import { capitalizeFirstLetter, testId } from '@lojinha/helpers'
import { Menu } from '../../../../../types'

type MenuItemProps = {
  item: Menu
  onClick: (menu: Menu) => void
}

export const MenuItem: FC<MenuItemProps> = ({
  item: { title, route, children, isNew, image },
  onClick,
}) => {
  return (
    <Box
      key={route}
      {...testId(`menu-item${route}`)}
      onClick={() =>
        onClick({
          title,
          route,
          children,
          isNew,
          image,
        })
      }
    >
      <Stack key={route}>
        <Box marginLeft="x16">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingLeft="x16"
          >
            <Text textSize="x20">{title && capitalizeFirstLetter(title)}</Text>
            {children && (
              <Box marginRight="x32">
                <ChevronRight color="black" />
              </Box>
            )}
          </Box>
          <Divider />
        </Box>
      </Stack>
    </Box>
  )
}
