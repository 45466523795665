import { Coordinates } from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'

export const getZipCodeAndNumberByCoordinates = async ({
  latitude,
  longitude,
}: Coordinates) => {
  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.GEOCODE_KEY}`

    const response = await fetch(url)
    const geocodeResponse = await response.json()
    const address = geocodeResponse.results[0].formatted_address as string

    const filteredZipCode = address.match(/\d{5}-?\d{3}/g)
    const zipCode = filteredZipCode && filteredZipCode[0]

    const filteredNumber = address.split(',')[1]
    const number = filteredNumber && filteredNumber.trim().split(' - ')[0]

    return {
      zipCode,
      number,
    }
  } catch (err) {
    captureException(err)
    return {
      zipCode: null,
      number: null,
    }
  }
}
