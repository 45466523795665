import { Box, Center, Grid, Stack } from '@lojinha/design-system'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  AccountButton,
  BagButton,
  Logo,
  Menu,
  WillDelivery,
  SearchInputDesktop,
} from '../../components'

const StickySection = styled.header<{ modalOpened?: boolean }>(
  ({ theme, modalOpened }) => `
  position: fixed;
  width: 100%;
  height: ${theme.space.x96};
  top: -${modalOpened ? theme.space.x96 : 0};
  background-color: ${theme.colors.white};
  z-index: 9;
  box-shadow: ${theme.shadows.gray16};
`
)

const Container = styled(Box)(
  ({ theme }) => `
    box-shadow: inset 0 -1px 0 ${theme.colors.gray200};
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    li {
      &::before {
        content: none;
      }
    }
  `
)

type HeaderDesktopProps = {
  onClickBag: () => void
  onClickWillDelivery: () => void
  address?: string
  modalOpened?: boolean
  showSearch?: boolean
}

export const HeaderDesktop: FC<HeaderDesktopProps> = ({
  onClickBag,
  onClickWillDelivery,
  address,
  modalOpened,
  showSearch,
}) => {
  return (
    <Box
      display={['none', 'none', 'block']}
      height="100%"
      // htmlAttrs={{
      //   style: {zIndex:2}
      // }}
    >
      <StickySection modalOpened={modalOpened}>
        <Container>
          <Center htmlAttrs={{ style: { width: '100%' } }}>
            <Box as="nav" width="100%">
              <Grid gridTemplateColumns="repeat(12, 1fr)">
                <Grid gridColumn="2 / 3">
                  <Logo height="x46" />
                </Grid>
                <Grid gridColumn="3 / 12">
                  <Stack
                    layout="horizontal"
                    htmlAttrs={{ style: { justifyContent: 'space-between' } }}
                  >
                    <Menu isMobile={false} />
                    {showSearch && <SearchInputDesktop />}
                    <Box minWidth={485} marginLeft="x0">
                      <Stack layout="horizontal" margin="x8">
                        <WillDelivery
                          onClickWillDelivery={onClickWillDelivery}
                          address={address}
                        />
                        <AccountButton />
                        <BagButton onClick={onClickBag} />
                      </Stack>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Center>
        </Container>
      </StickySection>
    </Box>
  )
}
