import React, { FC, useState, useEffect } from 'react'
import { HandTalkButton } from './HandTalkButton'

declare global {
  interface Window {
    ht: any
    HT: any
    isHTOpen: boolean
  }
}

type Props = {
  withButton?: boolean
}

export const HandTalk: FC<Props> = ({ withButton }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const toggleIsOpen = (open: boolean) => {
    localStorage.setItem('isHTOpen', open ? 'true' : 'false')
  }

  const loadHandTalk = () => {
    return new Promise((res: (value?: unknown) => void) => {
      if (window.ht) {
        setIsLoaded(true)
        return res()
      }

      const el = document.createElement('script')
      el.type = 'text/javascript'
      el.src = 'https://plugin.handtalk.me/web/latest/handtalk.min.js'
      el.async = true
      document.getElementsByTagName('head')[0].appendChild(el)
      el.onload = () => {
        window.ht = new window.HT({
          token: process.env.HANDTALK_KEY,
          mobileConfig: {
            side: 'left',
            actionsAlign: 'top',
          },
        })
        setIsLoaded(true)
        res()
      }
    })
  }

  const openHandtalk = async () => {
    if (isLoaded) {
      setTimeout(() => {
        const container = document.querySelector<HTMLDivElement>('.ht-skip')
        const button = container?.querySelectorAll('button')?.[0]

        button?.click()
      }, 1000)
    }
  }

  useEffect(() => {
    loadHandTalk()

    const views = Number(localStorage.getItem('htViews') ?? 0) + 1
    localStorage.setItem('htViews', views.toString())

    if (localStorage.getItem('isHTOpen') === 'true') {
      openHandtalk()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoaded) return

    const onActivated = () => {
      toggleIsOpen(true)
    }
    const onDeactivated = () => {
      toggleIsOpen(false)
    }

    window.ht.on('activated', onActivated)
    window.ht.on('deactivated', onDeactivated)
    return () => {
      window.ht.off('deactivated', onActivated)
      window.ht.off('deactivated', onDeactivated)
    }
  }, [isLoaded])

  return <>{withButton && <HandTalkButton onClick={openHandtalk} />}</>
}
