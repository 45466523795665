import { useEffect } from 'react'
import { useDebounce } from '@lojinha/helpers'
import {
  MarketableKind,
  SearchQuery,
  SearchQueryVariables,
  useLazyQuery,
} from '@lojinha/palantir'
import { useLojinhaContext } from '@lojinha/context'
import { DisplayItems } from '../../page-content/page-content.types'
import { SEARCH_QUERY } from './query'

const SEARCH_DELAY = 500

export const useSearch = (term = '') => {
  const [search, { loading, data, error }] = useLazyQuery<
    SearchQuery,
    SearchQueryVariables
  >(SEARCH_QUERY)

  const { nodes, totalCount } = data?.search.results || {}

  const { debouncedValue, debouncing } = useDebounce(term, SEARCH_DELAY)

  const hasResults = !!totalCount

  const { centerId: distributionCenterId } = useLojinhaContext()

  useEffect(() => {
    if (debouncedValue.length > 3) {
      search({ variables: { term: debouncedValue, distributionCenterId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, distributionCenterId])

  const itemsByKind = {
    [MarketableKind.Product]: {
      items: [] as DisplayItems[],
    },
    [MarketableKind.Kit]: {
      items: [] as DisplayItems[],
    },
    [MarketableKind.Meal]: {
      items: [] as DisplayItems[],
    },
  }

  ;(nodes || []).forEach(item => {
    itemsByKind[item.kind].items.push(item)
  })

  return {
    loading,
    error,
    debouncing,
    hasResults,
    results: [
      {
        kind: MarketableKind.Product,
        ...itemsByKind[MarketableKind.Product],
      },
      {
        kind: MarketableKind.Kit,
        ...itemsByKind[MarketableKind.Kit],
      },
      {
        kind: MarketableKind.Meal,
        ...itemsByKind[MarketableKind.Meal],
      },
    ],
    totalCount: totalCount || 0,
  }
}
