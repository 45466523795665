import * as imgix from '@lojinha/helpers'
import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'

type CardType = 'individual' | 'dish' | 'kit' | 'highlight'

const maxHeightValue = (cardType: CardType) => css`
  ${({ theme }) =>
    cardType === 'individual'
      ? `
        max-height: 104px;

        ${theme.media.above('sm')} {
          max-height: 184px;
        }
      `
      : ''};

  ${({ theme }) =>
    cardType === 'dish'
      ? `
        max-height: 170px;

        ${theme.media.above('md')} {
          max-height: 336px;
        }
      `
      : ''};

  ${cardType === 'kit' ? 'max-height: 72px' : ''};
`

const heightValue = (cardType: CardType) => css`
  ${({ theme }) =>
    cardType === 'individual'
      ? `
        height: 104px;

        ${theme.media.above('sm')} {
          height: 184px;
        }
      `
      : ''};

  ${({ theme }) =>
    cardType === 'dish'
      ? `
        height: 170px;

        ${theme.media.above('md')} {
          height: 336px;
        }
      `
      : ''};

  ${cardType === 'kit' ? 'height: 72px' : ''};

  ${({ theme }) =>
    cardType === 'highlight'
      ? `
        height: 109px;

        ${theme.media.above('md')} {
          height: 140px;
        }
        
        ${theme.media.below('sm')} {
          height: 72px;
        }
      `
      : ''};
`

const widthValues = (cardType: CardType) => css`
  ${({ theme }) =>
    cardType === 'highlight'
      ? `
        min-width: calc((100% - 80px) / 6);
        ${theme.media.below('sm')} {
          min-width: calc((100% - 32px) / 3);
        }
      `
      : ''};

  ${cardType === 'kit'
    ? `
        max-width: 72px;
        width: 100%;
      `
    : ''};

  ${cardType !== 'kit' && cardType !== 'highlight'
    ? `
        min-width: 100%;
        width: auto;
      `
    : ''};
`

const ImageWrapper = styled.div<{
  cardType: CardType
  isHover?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: ${({ theme, cardType }) =>
    cardType === 'kit' ? '100%' : theme.space.x8};
  background: ${({ theme }) => theme.colors.gray100};
  ${({ cardType }) => heightValue(cardType)}
  ${({ cardType }) => widthValues(cardType)}

  & div {
    position: relative;
    border-radius: ${({ theme }) => theme.space.x8};
    ${({ cardType }) => maxHeightValue(cardType)}
    ${({ cardType }) => widthValues(cardType)}
  }
  & img {
    ${({ cardType }) => widthValues(cardType)}
    object-fit: cover;
    transition: transform 300ms ease-out;
    border-radius: ${({ theme }) => theme.space.x8};
    &:hover {
      transition: transform 300ms;
      transform: scale(1.1);
    }
    ${({ isHover }) => (isHover ? 'transform: scale(1.1);' : '')}
  }
`

const PureImage = styled.img<{
  cardType: CardType
  isHover?: boolean
}>`
  position: absolute;
  width: 100%;
  object-fit: cover;
  ${({ cardType }) => heightValue(cardType)}
`

type Props = {
  url: string
  ariaLabel: string
  cardType?: CardType
  isHover?: boolean
}

export const ItemImage = ({
  url,
  ariaLabel,
  cardType = 'individual',
  isHover,
}: Props) => {
  const urlLqip = imgix.withParams(`${imgix.DOMAIN}${url}`, {
    px: 16,
    blur: 200,
    fm: 'webp',
  })

  const urlOptimized = imgix.withParams(`${imgix.DOMAIN}${url}`, {
    fm: 'webp',
  })

  return (
    <ImageWrapper cardType={cardType} aria-hidden={true} isHover={isHover}>
      <PureImage
        src={urlLqip}
        alt={ariaLabel}
        cardType={cardType}
        loading="lazy"
        aria-hidden={true}
      />
      <Image
        src={urlOptimized.replace(imgix.DOMAIN, '/')}
        alt={ariaLabel}
        layout="fill"
      />
    </ImageWrapper>
  )
}
