import { useLojinhaContext } from '@lojinha/context'
import { redirectToCheckout } from '@lojinha/helpers'
import { useEffect, useState } from 'react'
import {
  CartSyncItemInput,
  CartSyncMutation,
  CartSyncMutationVariables,
  FreeShippingValueQuery,
  useApolloClient,
  useMutation,
} from '@lojinha/palantir'
import { BagProps } from '@lojinha/context/src/types'
import { Router } from '@lojinha/vitrine/router'
import { setCartCookie } from '@lojinha/vitrine/src/helpers/cartId'
import { FREE_SHIPPING_VALUE, CART_SYNC } from './queries'
import { adaptBagToCartItemsInput } from './logic'

export const useGoToCheckout = () => {
  const {
    bag,
    isAuthenticated,
    setCartId,
    setRouteAfterLogin,
  } = useLojinhaContext()

  const [isLoading, setIsLoading] = useState(false)

  const [cartSyncMutation] = useMutation<
    CartSyncMutation,
    CartSyncMutationVariables
  >(CART_SYNC)

  const cartSync = async (items?: CartSyncItemInput[]) => {
    return cartSyncMutation({
      variables: {
        input: {
          items: items,
          forceSync: true,
        },
      },
    })
  }

  const handleCartSync = async (currentBag?: BagProps) => {
    setIsLoading(true)

    const items = adaptBagToCartItemsInput(currentBag ?? bag)

    if (!items?.length) {
      setIsLoading(false)
      return
    }

    const { data } = await cartSync(items)

    const cartId = data?.cartSync?.cart?.id

    if (!cartId) {
      setIsLoading(false)
      return
    }

    setCartId(cartId)
    setCartCookie(cartId)

    return cartId
  }

  const handleCartSyncAndRedirect = async () => {
    if (!isAuthenticated) {
      setRouteAfterLogin('/checkout')
      Router.push('/identification')

      return
    }

    await handleCartSync()

    redirectToCheckout()
  }

  return {
    isLoading,
    handleCartSyncAndRedirect,
    handleCartSync,
  }
}

export const useShippingValue = () => {
  const {
    freeShippingValue,
    setFreeShippingValue,
    isBagOpen,
  } = useLojinhaContext()

  const client = useApolloClient()

  const getFreeShippingValue = async () => {
    const { data } = await client.query<FreeShippingValueQuery>({
      query: FREE_SHIPPING_VALUE,
    })

    setFreeShippingValue(data.freeShippingValue)
  }

  useEffect(() => {
    if (isBagOpen) {
      getFreeShippingValue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBagOpen])

  return {
    freeShippingValue,
  }
}
