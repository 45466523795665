import React from 'react'

import { Menu } from '../../../types'
import { MenuLoading } from './components/loading'
import { useMenuNavigation } from './hook'
import { MenuDesktopUI } from './ui'

export const MenuDesktop = ({
  menu,
  isLoading = false,
}: {
  menu: Menu[]
  isLoading?: boolean
}) => {
  const {
    closeMenuOnBlur,
    closeMenu,
    isMenuOpen,
    openMenu,
    onClickMenuItem,
    selectedSubMenu,
    setSelectedSubMenu,
    handlerNavigationRef,
    categoryItemRef,
    submenuItemRef,
    onPanelKeyboardNavigation,
    onCategoryKeyboardNavigation,
    onSubmenuKeyboardNavigation,
  } = useMenuNavigation(menu)

  if (isLoading) return <MenuLoading />

  return (
    <MenuDesktopUI
      handleClickMenu={onClickMenuItem}
      onCloseMenu={closeMenu}
      closeMenuOnBlur={closeMenuOnBlur}
      isMenuOpen={isMenuOpen}
      onOpenMenu={openMenu}
      menuItems={menu}
      selectedSubMenu={selectedSubMenu}
      setSelectedSubMenu={setSelectedSubMenu}
      handlerNavigationRef={handlerNavigationRef}
      categoryItemRef={categoryItemRef}
      submenuItemRef={submenuItemRef}
      onPanelKeyboardNavigation={onPanelKeyboardNavigation}
      onCategoryKeyboardNavigation={onCategoryKeyboardNavigation}
      onSubmenuKeyboardNavigation={onSubmenuKeyboardNavigation}
    />
  )
}
