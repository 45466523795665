import { BagProps } from '@lojinha/context/src/types'
import { CartSyncItemInput, MarketableKind } from '@lojinha/palantir'

export const adaptBagToCartItemsInput = (bag?: BagProps) => {
  if (!bag) return

  return bag.cartItems.reduce((cartItems, itemGroup) => {
    if (!!itemGroup.item.id && !!itemGroup.item.kind && !!itemGroup.quantity) {
      return [
        ...cartItems,
        {
          productId: itemGroup.item.id,
          // Fix ATV-103 - some local carts have kind as 'Product' instead of 'PRODUCT'
          // should be a temporary fix, since the API will send the right value from now on
          kind: itemGroup.item.kind.toUpperCase() as MarketableKind,
          quantity: itemGroup.quantity,
        },
      ]
    }
    return cartItems
  }, [] as CartSyncItemInput[])
}
