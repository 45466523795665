import {
  AddressKind,
  SaveDeliveryAddressInput,
  DeliveryAddressSaveMutation,
  DeliveryAddressSaveMutationVariables,
  useMutation,
} from '@lojinha/palantir'
import { DELIVERY_ADDRESS_SAVE_MUTATION } from '../queries'
import { makeAddressId } from './logic'
import { getCoordinates } from './maps'
import {
  Address,
  AddressData,
  UseSaveAddress,
  UseSaveAddressOptions,
} from './types'

export const useSaveAddress = (
  options?: UseSaveAddressOptions
): UseSaveAddress => {
  const defaultValues: Partial<AddressData> = {
    country: 'BR',
  }

  /**
   * DeliveryAddress
   */
  const [saveDeliveryAddressMutation] = useMutation<
    DeliveryAddressSaveMutation,
    DeliveryAddressSaveMutationVariables
  >(DELIVERY_ADDRESS_SAVE_MUTATION)

  const saveDeliveryAddress = async (addressData: AddressData) => {
    const {
      street,
      number,
      complement,
      zipCode,
      city,
      state,
      country,
      neighborhood,
    } = addressData

    const coordinates = await getCoordinates({
      street,
      number,
      city,
      state,
    })

    if (!coordinates) {
      throw new Error('Failed to get coordinates')
    }

    const input: SaveDeliveryAddressInput = {
      address: {
        street,
        number,
        complement,
        zipCode,
        city,
        state,
        country,
        neighborhood,
        coordinates,
      },
    }

    await saveDeliveryAddressMutation({
      variables: {
        input,
      },
    })

    const createdAddress: Address = {
      ...input.address,
      id: makeAddressId(input.address),
      kind: AddressKind.Delivery,
      lastUsedAt: new Date().toISOString(),
    }

    options && options.onCreate(createdAddress, true)
  }

  const onSubmit = async (addressData: AddressData) => {
    await Promise.all([saveDeliveryAddress(addressData)])
  }

  return {
    defaultValues,
    onSubmit,
  }
}
