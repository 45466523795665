import { captureException } from '@lojinha/sentry'

type GeocodeAddress = {
  street: string
  number?: string
  city: string
  state: string
}

const sanitize = (content: string) =>
  // this is necessary to convert data to google geocoder format https://developers.google.com/maps/documentation/geocoding/start
  // credits: Becca Arantes code at livup-website-api
  content
    .replace(/\s/g, '+')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const composeGeocodeUrl = (address: GeocodeAddress) => {
  const { street, city, state, number } = address
  const query = [street, number || '', city, state]
    .filter(part => part)
    .map(sanitize)
    .join(',')

  return `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${process.env.GEOCODE_KEY}`
}

export const getCoordinates = async (
  address: GeocodeAddress
): Promise<
  | {
      latitude: number
      longitude: number
    }
  | undefined
> => {
  try {
    const url = composeGeocodeUrl(address)

    const response = await fetch(url)

    const geocodeResponse = await response.json()

    const { location } = geocodeResponse.results[0].geometry

    return Promise.resolve({
      latitude: parseFloat(location.lat),
      longitude: parseFloat(location.lng),
    })
  } catch (err) {
    captureException(err, { fingerprint: 'Error fetching address coordinates' })
    return Promise.resolve(undefined)
  }
}
