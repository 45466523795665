import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { beholder } from '@lojinha/beholder'

import { Router } from '../../../../router'
import { formatSlashCaseToDash } from '../../../helpers/formatSlashCaseToDash'
import { Menu } from '../../../types'

export const useMenuNavigation = (items: Menu[]) => {
  const router = useRouter()
  const { query, asPath } = router
  const url = asPath.split('?')[0]
  const MENU_INITIAL_ROUTE = 'cardapio'

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedSubMenu, setSelectedSubMenu] = useState<Menu | null>(null)

  const onOpenMenu = () => {
    setIsMenuOpen(true)
    router.push({
      pathname: url,
      query: { ...router.query, menu: MENU_INITIAL_ROUTE },
    })
  }

  const onCloseMenu = () => {
    setIsMenuOpen(false)
    setSelectedSubMenu(null)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { menu, ...rest } = router.query
    router.push({
      pathname: url,
      query: rest,
    })
  }

  const changeRouterMenu = (route: string) => {
    const changedQuery = formatSlashCaseToDash(route)

    router.push({
      pathname: url,
      query: { ...router.query, menu: changedQuery },
    })
  }

  const goToMenuPage = (route: string) => {
    Router.push(route)
    setIsMenuOpen(false)
    setSelectedSubMenu(null)
    window.scrollTo(0, 0)
  }

  const changeCurrentMenu = (selectedMenuItem: Menu) => {
    selectedMenuItem.route && changeRouterMenu(selectedMenuItem.route)
  }

  const handleClickMenu = (menu: Menu) => {
    let menuRouteProperty

    if (menu.children) {
      changeCurrentMenu(menu)
      setSelectedSubMenu(menu)
    } else {
      menuRouteProperty = menu.route
      menu.route && goToMenuPage(menu.route)
    }

    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'mobile_menu_navigation',
      label: menu.title?.toLowerCase(),
      property: menuRouteProperty,
    })
  }

  const goBackOnMenu = () => {
    setSelectedSubMenu(null)

    router.push({
      pathname: url,
      query: { ...router.query, menu: MENU_INITIAL_ROUTE },
    })
  }

  const getMenuByQuery = () => {
    items.forEach(item => {
      const submenu = item.children

      const menuByQuery = submenu?.filter(menuItem => {
        const route = menuItem.route
          ? formatSlashCaseToDash(menuItem.route)
          : MENU_INITIAL_ROUTE

        return route === query.menu
      })

      if (menuByQuery?.length) {
        changeCurrentMenu(menuByQuery[0])
      }
    })
  }

  const currentMenuRoute =
    items.length === 1
      ? items.map(menuItem => {
          const { route } = menuItem
          return route && formatSlashCaseToDash(route)
        })[0]
      : MENU_INITIAL_ROUTE

  useEffect(() => {
    const { menu } = query

    if (!menu) {
      return setIsMenuOpen(false)
    }

    if (menu !== currentMenuRoute) {
      menu === MENU_INITIAL_ROUTE ? goBackOnMenu() : getMenuByQuery()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return {
    selectedSubMenu,
    isMenuOpen,
    onOpenMenu,
    onCloseMenu,
    handleClickMenu,
    goBackOnMenu,
  }
}
