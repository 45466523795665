import * as brands from '@lojinha/design-system/src/icons'
import { BankSlip } from '@lojinha/design-system/src/icons'
import * as badges from './assets/badges'
import { Facebook, Instagram, Twitter, Whatsapp } from './assets/network'
import * as stores from './assets/stores'

export const DATA_FOOTER = {
  logoAlt: 'Liv Up',
  comeMeet: {
    title: 'Venha conhecer',
    links: [
      {
        label: 'Ajuda na Primeira Compra',
        url: '/primeira-compra',
      },
      {
        label: 'Sobre a Liv Up',
        ariaLabel: 'Sobre a Livápi',
        url: '/dna-liv-up',
      },
      {
        label: 'Impacto positivo',
        url: '/impacto-positivo',
      },
      {
        label: 'Alimentação saudável',
        url: '/alimentacao-saudavel',
      },
      {
        label: 'Na mídia',
        url: '/assessoria-de-imprensa-liv-up',
      },
      {
        label: 'Área de entrega',
        url: '/area-de-entrega',
      },
      {
        label: 'Blog',
        url: 'https://blog.livup.com.br/',
        ariaLabel: 'Blógui',
        externalLink: true,
      },
    ],
  },
  bePartner: {
    title: 'Seja parceiro',
    links: [
      {
        label: 'Impacto social',
        url: '/impacto-social',
      },
      {
        label: 'Influenciadores',
        url: '/parceiros-liv-up/influenciadores',
      },
      {
        label: 'Nutricionistas',
        url: '/parceiros-liv-up/nutricionistas',
      },
      {
        label: 'Empresas',
        url: '/parceiros-liv-up/seja-um-parceiro',
      },
    ],
  },
  contactUs: {
    title: 'Fale conosco',
    links: [
      {
        label: 'Troque uma ideia',
        url: 'https://ajuda.livup.com.br/hc/pt-br/requests/new',
        externalLink: true,
      },
      {
        label: 'Venha para o time',
        url: '/venha-para-o-time',
      },
    ],
  },
  helpTermsPolicies: {
    links: [
      {
        label: 'Dúvidas frequentes',
        url: 'https://ajuda.livup.com.br/hc/pt-br',
        externalLink: true,
      },
      {
        label: 'Precisa de ajuda',
        url: 'https://ajuda.livup.com.br/hc/pt-br/requests/new',
        externalLink: true,
      },
      {
        label: 'Termos de uso',
        url: '/termos-e-condicoes-de-uso',
      },
      {
        label: 'Política de privacidade',
        url: '/politica-de-privacidade',
      },
    ],
  },
  paymentMethods: {
    title: 'Formas de pagamento',
    brands: [
      {
        alt: 'Cartōes mastercard',
        image: brands.Mastercard,
      },
      {
        alt: 'Cartōes visa',
        image: brands.Visa,
      },
      {
        alt: 'American express',
        image: brands.Amex,
      },
      {
        alt: 'Dinners club',
        image: brands.Dinners,
      },
      {
        alt: 'Aura',
        image: brands.Aura,
      },
      {
        alt: 'Hiper card',
        image: brands.Hipercard,
      },
      {
        alt: 'Cartōes elo',
        image: brands.Elo,
      },
      {
        alt: 'Alelo refeição e alimentação',
        image: brands.Alelo,
      },
      {
        alt: 'Sodexo refeição e alimentação',
        image: brands.Sodexo,
      },
      {
        alt: 'Vale refeição e alimentação',
        image: brands.VrFood,
      },
      {
        alt: 'Ticket refeição e alimentação',
        image: brands.Ticket,
      },
      {
        alt: 'Ben visa vale',
        image: brands.Ben,
      },
      {
        alt: 'Green Card',
        image: brands.Greencard,
      },
      {
        alt: 'Vero Card',
        image: brands.Verocard,
      },
      {
        alt: 'Up',
        image: brands.UpBrasil,
      },
      {
        alt: 'Boleto',
        image: BankSlip,
      },
    ],
  },
  socialNetworks: {
    title: 'Redes sociais',
    links: [
      {
        alt: 'Instagram',
        url: 'https://www.instagram.com/livupoficial/',
        externalLink: true,
        icon: Instagram,
      },
      {
        alt: 'Facebook',
        url: 'https://www.facebook.com/livup.oficial',
        externalLink: true,
        icon: Facebook,
      },
      {
        alt: 'Whatsapp',
        url: 'https://api.whatsapp.com/send?phone=5511999145989',
        externalLink: true,
        icon: Whatsapp,
      },
      {
        alt: 'Twitter',
        url: 'https://twitter.com/livupoficial',
        externalLink: true,
        icon: Twitter,
      },
    ],
  },
  secureWebsite: {
    title: 'Site seguro',
    badges: [
      {
        alt: 'Selo de segurança Google, clique aqui para verificar',
        url:
          'https://transparencyreport.google.com/safe-browsing/search?url=www.livup.com.br',
        externalLink: true,
        image: badges.googleSafe,
      },
    ],
  },
  downloadApp: {
    title: 'Baixe nosso app',
    stores: [
      {
        image: stores.appStore,
        alt: 'Clique aqui para baixar na App Store do iPhone',
        url: 'https://apps.apple.com/br/app/liv-up/id1423221955',
        externalLink: true,
      },
      {
        image: stores.googlePlay,
        alt: 'Clique aqui para baixar no Google Play do Android',
        url:
          'https://play.google.com/store/apps/details?id=br.com.livup.ecommerce&hl=pt_BR',
        externalLink: true,
      },
    ],
  },
  copyright: `Liv Up © ${new Date().getFullYear()} - Todos os direitos reservados`,
}
